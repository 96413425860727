<app-sub-header></app-sub-header>

<div class="apl-det">
  <div class="" id="">
    <h1 class="display-6" id="usr-fullname">{{ this._userData?.userFirstName }} {{ this._userData?.userLastName }}</h1>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL2'">{{ this._userData?.employerName }}</h2>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL13'">{{ this._userData?.clientName }}</h2>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL13' && !this._userData?.clientName" >{{ this._userData?.employerName }}</h2>
    <h4 class="sub-dis"><span id="usr-id"></span></h4>
  </div>
  <hr class="style1">
  <div class="d-flex flex-wrap pt-3">
    <div class="" id="lft-cnt">
    <h3><span id="" >Thank you for confirming</span></h3>

    <p claSS="pt-2">You have confirmed you have nothing to submit for appeal number <B>{{_appealNo}}</B> on <B>{{_notSubDate | date:'MMMM dd, yyyy'}} {{_notSubDate | date:'hh:mm:ss a'}}</B></p>

  </div>
</div>
<div class="d-flex flex-wrap btn-cntr pt-5 cntrls">
  <div class="" id="">
  <input class="sqr-btn " type="button" (click)="printACopy()" value="Print a Copy"/>
  </div>
  <div class="cnf-lnk" id="">
     <span style="vertical-align: middle; line-height: 48pt;"> <a class="nav-link initial lnk-grn" aria-current="page" href="javascript:void(0);" (click)="finish()">Finish</a></span>
  </div>
</div>
