import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { IIntAppeal } from 'src/app/services/models/IIntAppeal';
import { IProfileData } from 'src/app/services/models/IProfileData';
import { AuthService } from 'src/app/services/shared/auth.service';




@Component({
  selector: 'app-appeal-sc-det',
  templateUrl: './appeal-sc-det.component.html',
  styleUrls: ['./appeal-sc-det.component.css']
})
export class AppealScDetComponent implements OnInit, OnChanges {
  @Input() _appealDet : IIntAppeal | undefined;
  @Input() _origin : string | undefined;
  @Input() _appealNo : string | undefined;
  @Input() _clientName : string | undefined;
  @Input() _isActive : boolean | undefined;
  _userData: IProfileData | undefined;
  _appealTypeDesc : String ='';


  constructor(private _authSrv: AuthService) {
    this._userData = this._authSrv.getUserProfile();
  }

  ngOnInit(): void {

    switch(this._appealDet?.appealType){
      case "ASM":
          this._appealTypeDesc = 'Assessment';
        break;
        case "CMP":
          this._appealTypeDesc = 'Compensation';
        break;
        case "OHS":
          this._appealTypeDesc = 'Occupational health and safety';
        break;
        default:
          this._appealTypeDesc = this._appealDet?.appealType!;
          break;
    }

  }


  ngOnChanges(changes: SimpleChanges): void{
    switch(this._appealDet?.appealType){
      case "ASM":
          this._appealTypeDesc = 'Assessment';
        break;
        case "CMP":
          this._appealTypeDesc = 'Compensation';
        break;
        case "OHS":
          this._appealTypeDesc = 'Occupational health and safety';
        break;
        default:
          this._appealTypeDesc = this._appealDet?.appealType!;
          break;
    }
  }
}
