import { IIdTokenClaims } from "./IIdTokenClaims";
import { IClaimBase } from "./IClaimBase";

export interface IProfileData{
  employerName: string;
  ean: string;
  wcatUserEmail: string;
  userFirstName: string;
  userLastName: string;
  claimBase: IClaimBase;
  time: Date;
  clientName: string;

}


export class ProfileData implements IProfileData{
  employerName: string;
  ean: string;
  wcatUserEmail: string;
  userFirstName: string;
  userLastName: string;
  claimBase: IClaimBase;
  time: Date;
  clientName: string;

  constructor(employerName: string,
              ean: string,
              wcatUserEmail: string,
              userFirstName: string,
              userLastName: string,
              claimBase: IClaimBase,
              time: Date,
              clientName: string
              ){
    this.employerName = employerName;
    this.ean = ean;
    this.wcatUserEmail = wcatUserEmail;
    this.userFirstName = userFirstName;
    this.userLastName = userLastName;
    this.claimBase = claimBase;
    this.time = time;
    this.clientName = clientName;

  }


}
