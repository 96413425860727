import { IDictionary } from "./IDictionary";

export class Dictionary<T> implements IDictionary<T>{
  private _items: { [index: string]: T } = {};
  private _count: number = 0;

  Add(key: string, value: T): void {
    if(!this._items.hasOwnProperty(key))
    this._count++;

    this._items[key] = value;
  }
  ContainsKey(key: string): boolean {
    return this._items.hasOwnProperty(key);
  }
  Count(): number {
    return this._count;
  }
  Item(key: string) {
    return this._items[key];
  }
  Keys(): string[] {
    var keySet: string[] = [];

        for (var prop in this._items) {
            if (this._items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }
        return keySet;
  }
  Remove(key: string) {
    var val = this._items[key];
    delete this._items[key];
    this._count--;
    return val;
  }
  Values(): T[] {
    var values: T[] = [];

        for (var prop in this._items) {
            if (this._items.hasOwnProperty(prop)) {
                values.push(this._items[prop]);
            }
        }

        return values;
  }


}
