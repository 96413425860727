import {AfterViewInit, Component, ViewChild, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import {PageEvent, MatPaginator } from '@angular/material/paginator';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import { Profile001Service } from "../../services/profile/profile001.service";
import { ActTracking } from '../../services/models/ActTracking';
import { IProfileData } from "../../services/models/IProfileData";
import { IIntAppealDocument } from 'src/app/services/models/IIntAppealDocument';



@Component({
  selector: 'app-appeal-sc-fls',
  templateUrl: './appeal-sc-fls.component.html',
  styleUrls: ['./appeal-sc-fls.component.css']
})
export class AppealScFlsComponent implements AfterViewInit  {
  @Input() _documents : IIntAppealDocument[] | undefined;
  @Input() _origin : string | undefined;
  @Input() _userData: IProfileData | undefined;
  @Input() _appealNo : string | undefined;
  @Output() selectedDocument : EventEmitter<IIntAppealDocument> = new EventEmitter();
  @Output() refresh : EventEmitter<string> = new EventEmitter();


  @ViewChild(MatPaginator) paginator?:MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  _listedDocsActions: ActTracking[] | undefined;
  _datasource :any;
  _docFilter !: UntypedFormGroup;
  displayedColumns = ['docstate','documentType','submissionDate', 'about'];
  length = 0;
  pageSize = 5;
  pageIndex = 0;
  showTotalPages = 3;
  pageSizeOptions = [5,10,20,30];
  showFirstLastButtons = true;

  paggedDocs : IIntAppealDocument[] | undefined;
  sortedDocs : IIntAppealDocument[] | undefined;


  constructor(private _liveAnnouncer: LiveAnnouncer,
              private _profsvc: Profile001Service,
              frmBld: UntypedFormBuilder) {
    this._docFilter = frmBld.group({
      Letters: false,
      Decisions: false,
      Submissions: false,
      Emails: false
    });





  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges):void{

    for (let propName in changes) {

      if(propName === '_documents'){
        this.sortedDocs = this._documents;
        this._datasource = new MatTableDataSource(this.sortedDocs);
        this._datasource.paginator = this.paginator;
      }

      if(propName === '_userData'){
        this._profsvc.getTracking(this._userData!.claimBase,"ListedDocumentActions").subscribe(trk => {
        this._listedDocsActions =trk;
        });
      }
    }
  }


  announceSortChange(sortState: Sort) {
     switch(sortState.active){
      case 'documentType':
          if(sortState.direction ==='asc'){
            this.sortedDocs?.sort((a,b) => {
              return a.documentTypeDesc.localeCompare(b.documentTypeDesc);
            });
          }else if(sortState.direction ==='desc'){
            this.sortedDocs?.sort((a,b) =>  {
              return b.documentTypeDesc.localeCompare(a.documentTypeDesc);
            });
          }else{
            this.sortedDocs?.sort((a,b) => new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime());
          }
          break;
      case 'submissionDate':
        if(sortState.direction ==='asc'){
          this.sortedDocs?.sort((a,b) =>  new Date(a.documentDate).getTime() - new Date(b.documentDate).getTime());
        }else if(sortState.direction ==='desc'){
          this.sortedDocs?.sort((a,b) => new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime());
        }else{
          this.sortedDocs?.sort((a,b) => new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime());
        }
        break;
      }
      this.setComponentDocuments();
  }

  updCheck(){
    let filteredDocs = new Array();
    let tempDocuments = this._documents?.slice();

    if(!this._docFilter.controls["Letters"].value &&
    !this._docFilter.controls["Decisions"].value &&
    !this._docFilter.controls["Submissions"].value &&
    !this._docFilter.controls["Emails"].value){
      filteredDocs.push(...this._documents!);
      this.sortedDocs = this._documents;
    }

    if(this._docFilter.controls["Letters"].value){
      filteredDocs.push(...tempDocuments?.filter(doc=> doc.documentTypeDesc?.toLowerCase()?.includes("letter"))!);
    }

    if(this._docFilter.controls["Decisions"].value){
      filteredDocs.push(...tempDocuments?.filter(doc=> doc.documentTypeDesc?.toLowerCase()?.includes("decision"))!);
    }

    if(this._docFilter.controls["Submissions"].value){
      filteredDocs.push(...tempDocuments?.filter(doc=> doc.documentTypeDesc?.toLowerCase()?.includes("submission"))!);
    }
    if(this._docFilter.controls["Emails"].value){
      filteredDocs.push(...tempDocuments?.filter(doc=> doc.documentTypeDesc?.toLowerCase()?.includes("email"))!);
      filteredDocs.push(...tempDocuments?.filter(doc=> doc.documentTypeDesc?.toLowerCase()?.includes("notification"))!);
    }

    this.sortedDocs = filteredDocs;
    this.setComponentDocuments();
  }



  setComponentDocuments(): void{

    this._datasource = new MatTableDataSource(this.sortedDocs);
    this._datasource.paginator = this.paginator;
  }

  handlePageEvent(event: PageEvent) {

    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.setComponentDocuments();
  }



  showFile(document: IIntAppealDocument){

    this._profsvc.setOpenDocTracking(this._userData!.claimBase, document);

    this.sortedDocs?.forEach(d=> {
      if(d.documentID === document.documentID)
        d.viewed = true;
    });
    this.selectedDocument.emit(document);
  }

  refreshLocal(){
    this.refresh.emit('refresh');
  }



}



