import { Injectable } from '@angular/core';
import { IIntEmployersForContact } from './models/IIntEmployersForContact';

@Injectable({
  providedIn: 'root'
})
export class WcatParameterService {
  orgSelectedEmployer!: string;
  orgSelectedWorker!: string;
  clientWorkerColumnForSorting!: string;
  employers: IIntEmployersForContact[] | undefined;
  submissionType: string | undefined;
  constructor() { }
}
