import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Appeal001Service } from 'src/app/services/appeal/appeal001.service';
import { IIntAppeal } from 'src/app/services/models/IIntAppeal';
import { INavData } from 'src/app/services/models/INavData';
import { IProfileData } from 'src/app/services/models/IProfileData';
import { Profile001Service } from 'src/app/services/profile/profile001.service';
import { AuthService } from 'src/app/services/shared/auth.service';
import { GlobalSupport } from 'src/app/services/shared/global-support';
import { NotificationService } from 'src/app/services/shared/notification.service';

@Component({
  selector: 'app-appealsearch',
  templateUrl: './appealsearch.component.html',
  styleUrls: ['./appealsearch.component.css']
})
export class AppealsearchComponent implements OnInit {
  @Input() origin : string | undefined;
  @Input() _userData: IProfileData | undefined;
  @Input() _wcatEmpRoleID: number  | undefined;
  @ViewChild('searchField') searchField: any;
  @ViewChild('datalist') datalist: any;
  appealsFound: IIntAppeal[] = [];
  searchText: string ="";
  appealNumber: string = "";
  notFound: string = '<div>We did not find an appeal associated with the claim, Review Division or appeal number you entered or the appeal is not visible on WCAT Online Services.<br>Check that you entered the number correctly.<br/><br/>If you have an appeal with WCAT and it is not visible, you can check its status online or visit our Help page.<br/><br/><ul><li>Check its status online is a link to <a href="https://www.wcat.bc.ca/home/check-the-status-of-an-appeal/"  target="_blank">https://www.wcat.bc.ca/home/check-the-status-of-an-appeal/</a></li><li>Visit our Help page is a link to the Help page on WCAT website.</li></ul></div>';
  private _navData: INavData | undefined;


  constructor(private _profsvc: Profile001Service,
    private _applsvc: Appeal001Service,
    private titleService: Title,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _globalSupport : GlobalSupport,
    private _notifyService : NotificationService,
    private _authSrv: AuthService) {
    }

  ngOnInit(): void {

    this._navData = this._globalSupport.getNavData();
    //this._userData = this._authSrv.getUserProfile();
  }

  searchFoucusOut(){
    this.searchText = this.searchText.trim();
  }

  search(){
    this.appealsFound = [];
    if(this.searchText == '' )
    return;

    if(this.searchText ===undefined || this.searchText.length > 15){
          setTimeout(() => { this.searchText = 'Incorrect Entry';},4000);
          return;
      }
      var orgsIds = this._userData!.claimBase.authEmpRoleID.map((itm)=> itm.wcatEmpRoleID);

      this._applsvc.getSearchAppeals(this.searchText.trim(),orgsIds).subscribe(response =>{
        if(response !== null){
          if(response.length == 1){
            this.navigateSearch(response[0]);
            return;
          }else if(response.length == 0){
            this.searchText = '  Not Found';
            this.appealNumber ='';
            this._notifyService.showInfo(this.notFound,'Entry Not Found');
            setTimeout(() => { this.searchText = '';},4000);
          }else{
         response.forEach(element => {
           if(element.appealNumber != null && this.appealsFound.findIndex(itm=> itm.appealNumber == element.appealNumber) == -1)
              this.appealsFound.push(element);
         });
         this.searchText = '';
         this.searchField.nativeElement.focus();
         this._notifyService.showInfoShort("<div>We found several entries.<br/>Please click the search box<br/> to see the results.<br/><br/>You will only see the results of appeals you are participating or invited to participate in.</div>",'');

         //this.datalist.nativeElement.style.display = "none";
        }
      }else{
        this.searchText = '  Not Found';
        this._notifyService.showInfo(this.notFound,'Entry Not Found');
        setTimeout(() => { this.searchText = '';},4000);
      }
    }, error =>{
      var txtErr = JSON.stringify(error);
      if (txtErr.includes("participation")) {
        this.searchText = '  Appeal Not Participating';
        var accessDenied: string = '<div>You can only see details of the appeal if you are participating in it.</div>';
        this.appealNumber ='';
          this._notifyService.showInfo(accessDenied,' Appeal Not Visible');
      }else { //(txtErr.includes("404") || txtErr.includes("403"))
          this.searchText = '  Appeal Not Found';
          var accessDenied: string = '<div>We did not find an appeal associated with the claim, Review Division or appeal number you entered or the appeal is not visible on WCAT Online Services. Check that you entered the number correctly. If you have an appeal with WCAT and it is not visible, you can <a href="https://www.wcat.bc.ca/home/check-the-status-of-an-appeal/"  target="_blank">check its status online</a> or visit our <a href="https://www.wcat.bc.ca/home/wcat-online-services"  target="_blank">Help page.</a> </div>';
          this.appealNumber ='';
            this._notifyService.showInfo(accessDenied,' Appeal Not Found');
        }
        //this._notifyService.showError('Something went wrong', `error: ${error.message}`);
        setTimeout(() => { this.searchText = '';},1000);

      }
      );
  }

  selectSearch(e: any): void{
    if(this.searchText === undefined || (this.searchText.length > 15 && this.searchText.length < 6)){
      setTimeout(() => { this.searchText = 'Incorrect Entry';},4000);
      return;
    }
    
    let appealFnd = this.appealsFound.find(a=>  a.appealNumber.toString() == this.searchText);
     
    //TODO Fix here this will be V2 if use is not participant on the Appeal we will show error message here
     //this._userData!.claimBase
    /* if(appealFnd)
    {
      var accessDenied = 'You can only see details of the appeal if you are participating in it.';
      this._notifyService.showInfo(accessDenied,' Access Denied');
      return;
    } */
     
    if(appealFnd){
      this.navigateSearch(appealFnd);
    }

  }




  navigateSearch(appealFnd: IIntAppeal){
    this._globalSupport.setNavData(appealFnd?.appealNumber, this.origin!,this.origin!, '','',0,appealFnd?.appealCaseID,appealFnd?.claimNumber,'','',Number(this._userData!.claimBase!.wCATOrgRoleId));
    if(this._router.url.includes('appeal-det')){
     this._router.navigate(['appeal-srch']);
    }else{
      this._router.navigate(['appeal-det']);
    }
  }

  keyDownHandler(event: any) {
    if (event.which === 32)
        event.preventDefault();
  }

  handleKeyUp(e: any): void{
    //e.preventDefault();
    if (e.which === 32)
        e.preventDefault();
    if(e.keyCode === 13){
      this.search();
    }
  }

}
