import { concatMap } from "rxjs";

export class UploadFileData{
  index: number;
  fileId: string;
  docCode: string;
  fileName: string;
  uploadProgress: number;
  file: File | undefined;
  message: string;
  processed: boolean;
  base64: string;
  mimeType: string;
  transferOk: boolean | undefined;

  constructor(index: number,
            fileId: string,
            docCode: string,
            fileName: string,
            uploadProgress: number,
            message: string,
            processed: boolean,
            base64: string,
            mimeType: string,
            file?: File,
            tranferOk?: boolean){
    this.index = index;
    this.fileId = fileId;
    this.docCode = docCode;
    this.fileName = fileName;
    this.uploadProgress = uploadProgress;
    this.file = file;
    this.message = message;
    this.processed = processed;
    this.base64 = base64;
    this.mimeType = mimeType;
    this.transferOk = tranferOk;
  }
}
