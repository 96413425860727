import { APP_INITIALIZER, NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from "./material.module";
import { AppRoutingModule, RoutingComponents } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from 'ngx-toastr';

import { AppInitService } from "./services/config/app-init.service";
import { Appeal001Service } from "./services/appeal/appeal001.service";
import { SubmissionsService } from "./services/appeal/submissions.service";
import { Profile001Service } from './services/profile/profile001.service';
import { NotificationService } from './services/shared/notification.service';

import { GlobalSupport } from "./services/shared/global-support";
import { AuthService } from "./services/shared/auth.service";
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/navigation/header.component';
import { SubHeaderComponent } from './components/shared/navigation/sub-header.component';
import { FooterComponent } from './components/shared/navigation/footer.component';
import { AppealShrtComponent } from './components/appeal/appeal-shrt.component';
import { AppealMngtComponent } from './components/appeal/appeal-mngt.component';
import { DragndroplistComponent } from './components/shared/support/dragndroplist.component';
import { AppealScDetComponent } from './components/appeal/appeal-sc-det.component';
import { AppealScPrcComponent } from './components/appeal/appeal-sc-prc.component';
import { AppealScJndComponent } from './components/appeal/appeal-sc-jnd.component';
import { AppealScPrtComponent } from './components/appeal/appeal-sc-prt.component';
import { AppealScDscComponent } from './components/appeal/appeal-sc-dsc.component';
import { AppealScFlsComponent } from './components/appeal/appeal-sc-fls.component';
import { EnumJoinPipe, EnumpartPipe, EnumdocPipe } from './services/pipes/enumpipe.pipe';
import { ArrReppipePipe, ArrEmployerPipe, ArrSubmissionLinks } from './services/pipes/arrpipe.pipe';
import { RepresentativeLandingComponent } from './components/representative/representative-landing.component';
import { AppealNthgComponent } from './components/appeal/appeal-nthg.component';
import { AppealNthgcnfmComponent } from './components/appeal/appeal-nthgcnfm.component';
import { ShowfileDialogComponent } from './components/shared/dialogs/showfile-dialog.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppealsearchComponent } from './components/shared/navigation/appealsearch.component';
import { MsalModule, MsalService, MsalInterceptor, MsalBroadcastService, MsalRedirectComponent, MsalGuardConfiguration, MSAL_INSTANCE, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { MsalGuard } from "./services/shared/msal.guard";
import { LogLevel, PublicClientApplication, InteractionType, BrowserCacheLocation, IPublicClientApplication } from "@azure/msal-browser";
import { msalConfig, protectedResources, environment } from "../environments/environment";
import { HomeComponent } from './home.component';
import { capitalCasePipe } from './services/pipes/utilities.pipe';
import { BnNgIdleService } from 'bn-ng-idle';
import { GlobalDataService } from './services/shared/global-data.service';
import { AppInsightService } from './services/shared/app-insight.service';
import { ErrorHandlingService } from './services/shared/error-handling.service';
import { AppealUpldComponent } from './components/appeal/appeal-upld.component';
import { AppealSubComponent } from './components/appeal/appeal-sub.component';
import { WcatParameterService } from './services/wcat-parameter.service';


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function loggerCallback(logLevel: LogLevel, message : string) {
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SubHeaderComponent,
    RoutingComponents,
    FooterComponent,
    AppealShrtComponent,
    AppealMngtComponent,
    DragndroplistComponent,
    AppealScDetComponent,
    AppealScPrcComponent,
    AppealScJndComponent,
    AppealScPrtComponent,
    AppealScDscComponent,
    AppealScFlsComponent,
    EnumJoinPipe,
    EnumpartPipe,
    EnumdocPipe,
    ArrReppipePipe,
    ArrEmployerPipe,
    ArrSubmissionLinks,
    RepresentativeLandingComponent,
    AppealNthgComponent,
    AppealUpldComponent,
    AppealNthgcnfmComponent,
    ShowfileDialogComponent,
    AppealsearchComponent,
    HomeComponent,
    capitalCasePipe,
    AppealSubComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    NgxFileDropModule,
    NgxExtendedPdfViewerModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: protectedResources.WCATAppeals.scopes
        }
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [protectedResources.WCATAppeals.endpoint, protectedResources.WCATAppeals.scopes],
         [protectedResources.WCATApim.endpoint, protectedResources.WCATApim.scopes]
        ])
      })
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [
      AppInitService,
      GlobalSupport,
      GlobalDataService,
      Appeal001Service,
      SubmissionsService,
      Profile001Service,
      NotificationService,
      AppInsightService,
      AuthService,
      Title,
      { provide: APP_INITIALIZER, useFactory: (initsvc : AppInitService) => () => initsvc.Init(), deps:[AppInitService], multi: true},
      { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
      MsalGuard,
      BnNgIdleService,
      { provide: ErrorHandler, useClass: ErrorHandlingService },
      WcatParameterService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
