import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatAccordion } from '@angular/material/expansion';


import { Profile001Service } from "../../services/profile/profile001.service";
import { Appeal001Service } from "../../services/appeal/appeal001.service";
import { GlobalSupport } from '../../services/shared/global-support';
import { IProfileData } from "../../services/models/IProfileData";
import { IIntAppeal } from 'src/app/services/models/IIntAppeal';
import { IIntJoinedappeals } from 'src/app/services/models/IIntJoinedappeals';
import { IIntParticipant } from 'src/app/services/models/IIntParticipant';
import { IIntAppealDocument } from 'src/app/services/models/IIntAppealDocument';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { UploadFileData } from 'src/app/services/models/UploadFileData';
import { ShowfileDialogComponent } from '../shared/dialogs/showfile-dialog.component';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { _isTestEnvironment } from '@angular/cdk/platform';
import { AuthService } from 'src/app/services/shared/auth.service';
import { IIntSubmissionlink } from 'src/app/services/models/IIntSubmissionlink';
import { INavData } from 'src/app/services/models/INavData';
import { HttpErrorResponse } from '@angular/common/http';
import { WcatParameterService } from 'src/app/services/wcat-parameter.service';



@Component({
  selector: 'app-appeal-det',
  templateUrl: './appeal-det.component.html',
  styleUrls: ['./appeal-det.component.css']
})
export class AppealDetComponent implements OnInit {
  @ViewChild(MatAccordion) accordion! : MatAccordion;
  _userData: IProfileData | undefined;
  _appealNo : string | undefined;
  _caseId: number | undefined;
  _origin: string | undefined = '';
  _document: IIntAppealDocument | undefined;
  _remainders: boolean = false;
  _appealStatus: string = '';
  _isFuture: boolean = false;
  _appealDet :IIntAppeal | undefined;
  _joinedAppeals : IIntJoinedappeals[] | undefined;
  _documents : IIntAppealDocument[] | undefined;
  _participants : IIntParticipant[] | undefined;
  _panelIdx = 0;
  _panelState: boolean[] = [false, false, false, false];
  _navData: INavData | undefined;
  _detailClientName: string = "";
  participantStatus = true;
  _typeOfHearing: string = "";
  _locationText: string = "";
  _typeOfHearingStaticText = "";

  constructor(private _profsvc: Profile001Service,
              private titleService: Title,
              private _applsvc: Appeal001Service,
              private _activatedRoute : ActivatedRoute,
              private _router : Router,
              private _globalSupport : GlobalSupport,
              private _spinner: NgxSpinnerService,
              public dialog: MatDialog,
              private _notifyService : NotificationService,
              private _authSrv: AuthService,
              private _wcatParameterService: WcatParameterService) {
                this._navData = this._globalSupport.getNavData();
                this._appealNo = this._navData!.appealNo;
                this._caseId = this._navData!.appealCaseID;
                this._origin = this._navData!.origin;

                if(this._appealNo === undefined || this._appealNo === null){
                  this._globalSupport.goHomeData();
                }

                this._userData = this._authSrv.getUserProfile();
                this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'APPLDET',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber, '','',Number(this._userData!.claimBase!.wCATOrgRoleId));
                this._globalSupport.addTracking(this._activatedRoute);

              }
  


  ngOnInit(): void {
    this.titleService.setTitle("WCAT Online Service - Appeal Details")
    this._spinner.show();
    this._applsvc.getAppealDetByCaseId(this._appealNo!, this._caseId!).subscribe(response =>{
      this._appealDet = response;

      this._appealStatus = this.displayStatus();

      /* if (this._appealDet?.hearingDate)
      {
        this._appealDet.hearingDate = new  Date(this._appealDet?.hearingDate.toString().replace(/-/g, "/"));  
    
      }  */

      if(this._appealDet.submissionLinks.length > 0)
        this._remainders = true;

      this.setOralHearingTypeValues();
      this._joinedAppeals = response.joinedAppeals.slice();
      this._participants = this.sortbyRole(response.participants.slice());

      this.validateIsFuture();

      this._applsvc.getDocuments(response.appealCaseID).subscribe(docsResponse =>{
        if(docsResponse != null && docsResponse.length > 0)
        {
          docsResponse.sort((a, b) =>{
            var a1 = new Date(a.documentDate).getTime();
            var b1 = new Date(b.documentDate).getTime();
            return b1 - a1;
          });
        }

        this._documents = docsResponse;


      if(this._userData?.claimBase.wCATRoleType == 'RL2')
        this._userData!.employerName = response?.employers[0].employerName;

      if(this._userData?.claimBase.wCATRoleType == 'RL13' )
         this.extractClientName();

        this._spinner.hide();
      },
      error =>{
        if (error instanceof HttpErrorResponse)
        {
          if (error.status === 400)
          {
            this.participantStatus = false;
            this._notifyService.showError('You are invited to participate', `If you wish to participate in the appeal process, please submit a Notice of Participation to WCAT. <br></br> Once WCAT reviews your participation form, you will see the appeal documents and will be asked to respond to the appellant's submission.`);
          }
          else
          {
            this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
          }
        }
        else
        {
          this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
        }
        this._spinner.hide();
      }
      );
    },
    error =>{
      this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
      this._spinner.hide();
    });

  }

  setOralHearingTypeValues(): void{
    var currentDate: Date = new Date();
    //var myDate = new Date();
    if (this._appealDet?.hearingDate! < currentDate)
    {
      this._typeOfHearing = "";
      this._locationText = "";
      this._typeOfHearingStaticText ="";
    }
    else
    {
      //OH500		In-Person
      //OH501		Virtual
      //OH502		Blended Videoconference
      //OH503		Blended Call-in
      switch (this._appealDet?.oralHearingTpCd) {
          case 'OH500':
            this._typeOfHearing = "In Person";
            this._locationText = this._appealDet.hearingLocation;
            this._typeOfHearingStaticText = "";
              break;
          case 'OH501':
            this._typeOfHearing = "Videoconference and/or Teleconference";
            this._locationText = "";
            this._typeOfHearingStaticText = "Please check WCAT correspondence for participation details.";
              break;
          case 'OH502':
            this._typeOfHearing = "In Person and Videoconference ";
            this._locationText = this._appealDet.hearingLocation;
            this._typeOfHearingStaticText = "Please check WCAT correspondence for participation details.";
              break;
          case 'OH503':
            this._typeOfHearing = "In Person and Teleconference";
            this._locationText = this._appealDet.hearingLocation;
            this._typeOfHearingStaticText = "Please check WCAT correspondence for participation details.";
                break;
        }
      
    }
  }

  extractClientName():void{
    if(this._participants!.length > 0){
      if ((null != this._wcatParameterService.employers) && (this._wcatParameterService.employers))
      {
        for(let employer of this._wcatParameterService.employers!){
          let participant = this._participants!.filter(prt => prt.representedBy?.repRoleID ==  Number(employer.employerRoleID));
          if(participant.length > 0)
          {
            this._userData!.clientName = participant[0].representedBy.repCompanyName ?? (participant[0].firstName + ' ' + participant[0].lastName);
            if(participant != null && participant.length > 0){
              if (participant[0].companyName)
              {
                this._detailClientName = participant[0].companyName;         
              }
              else
              {
                this._detailClientName = participant[0].firstName + ' ' + participant[0].lastName;
              }
              //this._userData!.clientName = participant[0].representedBy.repCompanyName ?? (participant[0].firstName + ' ' + participant[0].lastName);
              break;
            }
        }
      }
      
    }
     
    }
  }

  validateIsFuture(): void{

    var hrDt = new Date(this._appealDet?.hearingDate! );
    hrDt.setHours(0, 0, 0, 0);
    var tdDt = new Date();
    tdDt.setHours(0, 0, 0, 0);

    if(this._appealDet?.hearingDate! && (hrDt >= tdDt))
      this._isFuture  = true;

    if(this._appealDet?.appealPath?.toLowerCase()?.includes('written'))
      this._isFuture  = true;

  }


  displayStatus(): string{
    var retStatus = '';
    switch(this._appealDet?.appealStatus){
      case 'Summary Closed':
        retStatus = 'Closed';
        break;
        default:
          retStatus = this._appealDet?.appealStatus!;
          break;
    }
    return retStatus;
  }

  sortbyRole(iniArr: IIntParticipant[]): IIntParticipant[] | undefined {
    let order = ["APP",
                "AREP",
                "RSP",
                "RREP",
                "AUTH",
                "WIP",
                "WIPREP",
                "WES",
                "WESDIV",
                "TPEMP",
                "JHSC",
                "WRKAR",
                "WRKRI",
                "WRKSC"],
        sortedParticipants: IIntParticipant[] = [];
    order.forEach(roleOrder=>{
      var result = iniArr.filter(item => item.participantRole == roleOrder);
      if(result.length > 0){
        sortedParticipants.push(...result);
      }
      });
      //check not in
      var notIn = iniArr.filter(item => !sortedParticipants.some(s => item.participantRoleID === s.participantRoleID));
      if(notIn.length > 0){
       sortedParticipants.push(...notIn);
      }
      return sortedParticipants;
    }

  setPanelIndex(index: number, state: string) {
    this._panelIdx = index;
    this._panelState[index] = state ==='O';
    for(let idx=0; idx < this._panelState.length; idx++){
      if(idx !==index)
      this._panelState[idx] = false; }
  }

  expandAllPanels(){
    this.accordion.openAll();
    for(let idx=0; idx < this._panelState.length; idx++){ this._panelState[idx] = true; }

  }

  closeAllPanels(){
    this.accordion.closeAll();
    for(let idx=0; idx < this._panelState.length; idx++){ this._panelState[idx] = false; }
  }


  selectedDocument(document: IIntAppealDocument){
    this._spinner.show();
    this._applsvc.getDocumentContent(document.documentID,this._appealDet!.appealCaseID).subscribe(reponse => {
    let filecontent = reponse,
    fileName = `${document.documentTypeDesc}`,
    file = new File([this.converBase64toBlob(filecontent.documentData, filecontent)],fileName,{ type: filecontent.mimeType });
    let item: UploadFileData = new UploadFileData(0,document.documentID.toString(),document.documentID.toString(), fileName,0,'',false,reponse.documentData,reponse.mimeType,file);
    this._spinner.hide();
    this.showFile(item);
    },
    error =>{
      var errorMsg = '<div>We apologize. <br/>The document you are trying to view is not available at this time. <br/>Please try again.<br/><br/> If you are still experiencing problems viewing the document, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) for assistance.</div>';
      this._spinner.hide();
      this._notifyService.showInfo(errorMsg,'File Cannot be Displayed');
    });
  }


  showFile(item: UploadFileData){
    this.dialog.open(ShowfileDialogComponent, { data: item,
      panelClass: 'full-width-dialog', width: '100%', disableClose: true });
  }

  contentToFile(content: string, filename: string) {
    var arr = content.split(','),
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}



 converBase64toBlob(content: any, contentType: any) {
  contentType = contentType || '';
  var sliceSize = 512;
  var byteCharacters = window.atob(content);
  var byteArrays = [
  ];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, {
    type: contentType
  });
  return blob;
}




  navMakeSub(appeal: IIntAppeal, item: IIntSubmissionlink){

    this._globalSupport.setNavData(appeal.appealNumber, this._origin?.substring(0,3).toUpperCase(), 'APPLDET', item.origDocumentType, item.respDocumentType, item.wcatCasePartRoleId, appeal.appealCaseID, appeal.claimNumber,'SUBMLNK','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-sub']);
  }

  genericSubmt(){
    this._globalSupport.setNavData(this._appealDet!.appealNumber, this._origin!.substring(0,3).toUpperCase(),'APPLDET', '', '', 0, this._appealDet!.appealCaseID, this._appealDet!.claimNumber,'SUBMGNRC','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-sub']);
  }

  navMakeNoSub(appeal: IIntAppeal, item: IIntSubmissionlink){
    this._globalSupport.setNavData(appeal.appealNumber, this._origin?.substring(0,3).toUpperCase(),'APPLDET', item.origDocumentType, item.respDocumentType, item.wcatCasePartRoleId, appeal.appealCaseID, appeal.claimNumber,'SUBMNOT','',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-not']);
  }


  isActiveAppeal(): boolean{
    var activeStatus = ['Received', 'Registered', 'Accepted', 'With Panel', 'Suspended'];
    return activeStatus?.includes(this._appealDet?.appealStatus?.trim()!);
  }


  refresh(cmd: string){
    this._spinner.show();
    this._applsvc.getDocuments(this._appealDet!.appealCaseID).subscribe(docsResponse =>{
      if(docsResponse != null && docsResponse.length > 0)
      {
        docsResponse.sort((a, b) =>{
          var a1 = new Date(a.documentDate).getTime();
          var b1 = new Date(b.documentDate).getTime();
          return b1 - a1;
        });
      }

      this._documents = docsResponse;
      this._spinner.hide();
    },
    error =>{
      this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
      this._spinner.hide();
    }
    );

  }
  
}



