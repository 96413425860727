<div id="grn-sqr">
  <div class="srch-grn">
    <div class="srch-txt"><b>Go directly to an appeal</b></div>
    <div class="srch-inpt-cntr">
      <div class="srch-inst">
        <div>Enter an appeal, claim or Review Division number</div>
      </div>
        <input class="srch-inpt" type="text" [(ngModel)]="searchText"  #searchField  list="lstSrchAppeals" (change)="selectSearch($event)" (keydown)='keyDownHandler($event)'  (keyup)="handleKeyUp($event)"  (focusout)="searchFoucusOut()" />
        <datalist id="lstSrchAppeals" #searchField [(ngModel)]="datalist">
          <select>
            <option *ngFor="let item of appealsFound" [value]="item.appealNumber" class="optitm"><span>Status: <b>{{item.appealStatus}}</b> Review: <b>{{item.reviewNumber}}</b> R.D. Date: <b>{{item.reviewDecisionDate}}</b></span></option>
          </select>

        </datalist>
        <input class="sqr-btn srch-btn srch-inpt-btn" type="button" value="Search" (click)="search()" />

        <div class="srch-inst">
          <div>You can find the appeal number at the top of any letter sent from WCAT.</div>
          <div></div>
        </div>
  </div>
</div>
</div>
