<div class="d-flex flex-wrap">
  <div>
    <h4></h4>
    <span></span>
  </div>
  <div class="sub-hdr-lft pt-4">
    <h4>Decision details</h4>
    <span></span>

  </div>
</div>
<div class="tab-cnt">
<div id="" class="main-cnt rnd-01 m-3">
  <div class="d-flex flex-wrap p-2">
    <div class="lft-col" id="">
          <div class="spt-1">
            <h5><span class="hdr-01" *ngIf="!isActiveAppeal()">ACTUAL DECISION DATE</span>
              <span class="hdr-01" *ngIf="isActiveAppeal()">EXPECTED DECISION DATE</span></h5>
            <span class="appl-itm" >{{_appealDet?.appealDecisionDate | date: "mediumDate"}}</span>
            <span class="appl-itm" *ngIf="isActiveAppeal()">{{_appealDet?.appealDueDate | date: "mediumDate"}}</span>
          </div>
        <div class="spt-1">
          <h5><span class="hdr-01"></span></h5>
          <span class="appl-itm"></span>
        </div>
    </div>
    <div class="mid-col" id="">
      <div class="spt-1">
        <h5><span class="hdr-01" *ngIf="_appealDet?.appealStatus=='Suspended'">REASON FOR SUSPENSION</span></h5>
        <span class="appl-itm" *ngIf="_appealDet?.appealStatus=='Suspended'">{{this._reason}}</span>
      </div>
      <div class="spt-1">
        <h5><span class="hdr-01"></span></h5>
        <span class="appl-itm"></span>
      </div>
    </div>
    <div class="rgt-col" id="">
      <div class="spt-1" *ngIf="!isActiveAppeal()">
        <h5><span class="hdr-01">RECONSIDERATION</span></h5>
        <span class="appl-itm"><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/get-a-final-decision#reconsideration" target="_blank">Reconsideration info <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></span>
      </div>
      <div class="spt-1">
        <h5><span class="hdr-01">&nbsp;</span></h5>
        <span class="appl-itm">&nbsp;</span>
      </div>

    </div>
  </div>
</div>
</div>
