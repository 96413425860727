import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


import { AppInitService } from "../../services/config/app-init.service";
import { IProfileData } from "../../services/models/IProfileData";
import { ActTracking } from '../models/ActTracking';
import { IIntAppealDocument } from '../models/IIntAppealDocument';
import { Router } from '@angular/router';
import { IClaimBase } from '../models/IClaimBase';
import { AuthService } from '../shared/auth.service';
import { IIntEmployersForContact } from '../models/IIntEmployersForContact';
import { IIntUserClaim } from '../models/IIntUserClaim';

@Injectable({
  providedIn: 'root'
})


export class Profile001Service {



  constructor(private _router: Router,
              private _http: HttpClient,
              private _inicfg: AppInitService) {

   }

  httpOptions = { headers: new HttpHeaders({
                                'Content-Type': 'application/json'
                                })
                };


  removeProfile(){
    sessionStorage.clear();
  }




  getTracking(claimbase: IClaimBase, category: string){
    return this._http
      .get<ActTracking[]>(this._inicfg.config?.ENDPOINT_BASE_NETCORE + `/wcat/profile/tracking/${claimbase.wCATRoleType}${claimbase.wCATRoleId}/${category}`)
      .pipe(
        retry(1),
        catchError(this.errorHandler)
      );
  }

  getActualProfile():Observable<IIntUserClaim>{
    return this._http.post<IIntUserClaim>(`${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/profile/profile`, this.httpOptions);
  }

  getEmployers(): Observable<IIntEmployersForContact[]>{
    return this._http.post<IIntEmployersForContact[]>(`${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/profile/employers`, this.httpOptions);
  }

  setOpenDocTracking(claimbase: IClaimBase, item: IIntAppealDocument){
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
    let payLoad: ActTracking = new ActTracking('','',new Date(),`${claimbase.wCATRoleType}${claimbase.wCATRoleId}`,'open','','VIEW','ListedDocumentActions',0, item.documentID.toString() ,'document',item.contentTypeName.toString(),item.comment,'');
    this._http.post(this._inicfg.config?.ENDPOINT_BASE_NETCORE + '/wcat/profile/tracking',JSON.stringify(payLoad),httpOptions).subscribe(response => {});
  }


  errorHandler(error: any){
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }

}
