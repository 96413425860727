import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { AppInitService } from "../config/app-init.service";
import { Profile001Service } from "../profile/profile001.service";
import { IProfileData } from "../models/IProfileData";
import { submissionData } from '../models/submissionData';
import { SubmitTextToFile } from '../models/SubmitTextToFile';
import { IResponseData } from '../models/IResponseData';
import { ISubmissionResponse } from '../models/ISubmissionResponse';


@Injectable({
  providedIn: 'root'
})
export class SubmissionsService {

  httpOptions = { headers: new HttpHeaders({
    'Content-Type': 'application/json'
    })
};

  constructor(private _inicfg: AppInitService,
    private _profsvc: Profile001Service,
    private _http: HttpClient) { }



    confirmNothingToSubmit(appealCaseID: number, respDocumentType: string, wcatCasePartRoleId: number, appealNumber: string):Observable<ISubmissionResponse>{

      let payLoad: submissionData = new submissionData(appealCaseID,respDocumentType,wcatCasePartRoleId, appealNumber,"NS");

      return this._http.post<ISubmissionResponse>(this._inicfg.config?.ENDPOINT_BASE_NETCORE + '/wcat/submission/nothing',JSON.stringify(payLoad),this.httpOptions);
    }

    submitTextToFile(appealCaseID: number, respDocumentType: string, wcatCasePartRoleId: number, appealNumber: string, subdesc: string):Observable<ISubmissionResponse>{
      let payLoad: submissionData = new submissionData(appealCaseID,respDocumentType,wcatCasePartRoleId, appealNumber,"TXT", subdesc);
      return this._http.post<ISubmissionResponse>(this._inicfg.config?.ENDPOINT_BASE_NETCORE + '/wcat/submission/text',JSON.stringify(payLoad),this.httpOptions);
    }

    uploadSubmissions(appealCaseID: number, respDocumentType: string, wcatCasePartRoleId: number,  submission: FormData, appealNo: string): Observable<HttpEvent<any>>{
      let headers = new HttpHeaders();
      headers.append('enctype', 'multipart/form-data')
      return this._http.post<HttpEvent<any>>(this._inicfg.config?.ENDPOINT_BASE_NETCORE + `/wcat/submission/evidence/${appealCaseID}/${respDocumentType}/${wcatCasePartRoleId}/${appealNo}`,submission,  { headers: headers, reportProgress: true, observe: 'events' })
                  .pipe(catchError(this.errorHandler));
    }

    genericSubmission(appealCaseID: number, appealNumber: string, submission: FormData): Observable<HttpEvent<any>>{
      let headers = new HttpHeaders();
      headers.append('enctype', 'multipart/form-data')
      return this._http.post<HttpEvent<any>>(this._inicfg.config?.ENDPOINT_BASE_NETCORE + `/wcat/submission/generic/${appealCaseID}/${appealNumber}`,submission,  { headers: headers, reportProgress: true, observe: 'events' })
                  .pipe(catchError(this.errorHandler));
    }

    errorHandler(error: HttpErrorResponse){
      let errorMessage = '';
      if(error.error instanceof ErrorEvent) {
        errorMessage = error.error.message;
      } else {
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      //window.alert(errorMessage);
      return throwError(errorMessage);
    }



}
