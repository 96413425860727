import { Router } from '@angular/router';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus, InteractionType, IPublicClientApplication, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment, b2cPolicies } from "../../../environments/environment";
import { AppInitService } from '../config/app-init.service';
import { Claim } from '../models/Auth';
import { IIdTokenClaims } from '../models/IIdTokenClaims';
import { AuthEmpRoleId, IIntAuthEmpRoleId } from '../models/IIntAuthEmpRoleId';
import { IIntEmployersForContact } from '../models/IIntEmployersForContact';
import { ClaimBase, IClaimBase } from '../models/IClaimBase';
import { IProfileData, ProfileData } from '../models/IProfileData';
import { Profile001Service } from '../profile/profile001.service';
import { GlobalSupport } from './global-support';
import { AppInsightService } from './app-insight.service';



@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  _userProfile: IProfileData | undefined = undefined;
  _selwCATOrgRoleId: string | undefined = "0";
  _claimBase: IClaimBase | undefined;
  _wCATRoleType: string | undefined = '';
  __cntr = 0;

  private readonly _destroying$ = new Subject<void>();
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private msalService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private _inicfg: AppInitService,
              private _router: Router,
              private _globalSupport : GlobalSupport,
              private _profileSvc: Profile001Service,
              private _loggingSvc :AppInsightService) {


                this.msalBroadcastService.msalSubject$.pipe(
                      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
                    ).subscribe((result: EventMessage) => {
                      const payload = result.payload as AuthenticationResult;
                      this.msalService.instance.setActiveAccount(payload.account);
                      this.setActiveProfile();
                    });


                this.msalBroadcastService.msalSubject$.subscribe({
                  next: (msalSubject) => {
                    if(msalSubject.eventType === 'msal:acquireTokenFailure' ||  msalSubject.eventType === EventType.LOGIN_FAILURE){
                      this.setActiveAccount();
                      return;
                    }
                  },
                  error: (error)=> {
                    this._loggingSvc._appInsights.trackException({exception: new Error(`Guard - error: ${error.message}`)});
                  }
                });

                this.msalBroadcastService.inProgress$.pipe(
                      filter((status: InteractionStatus) => status === InteractionStatus.None),
                      takeUntil(this._destroying$)
                    )
                    .subscribe(() => {
                      //********/
                      this.checkActiveAccount();
                      //this.getUserProfile();
                      this.setActiveAccount();
                    });

                this.msalService.instance.handleRedirectPromise()
                    .then(res=>{
                    })
                    .catch(err => {
                      //this.login();
                      this._loggingSvc._appInsights.trackException({exception: new Error(`Guard - error: ${err.message}`)});
                    });
              }





  initiate(){
    this._loggingSvc._appInsights.trackTrace({message: 'Auth Service initiate started'});
    this.setActiveAccount();
    var active = this.msalService.instance.getActiveAccount();
     if(active === undefined || !active || active === null){
         this.login();
     }else{

    if (!active && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.setActiveProfile();
    }

     }
     this._loggingSvc._appInsights.trackTrace({message: 'Auth Service initiate finished'});
  }


  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    this._loggingSvc._appInsights.trackTrace({message: 'Auth Service login started'});
      if (this.msalGuardConfig.authRequest){
        this.msalService.loginRedirect({...this.msalGuardConfig.authRequest, ...userFlowRequest} as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
      this.setActiveAccount();
      this._loggingSvc._appInsights.trackTrace({message: 'Auth Service login finished'});
  }




clearStoage(){
  this.msalService.instance.setActiveAccount(null);
  sessionStorage.clear();
  localStorage.clear();
}


  logoutFlow() {
    this.msalService.instance.setActiveAccount(null);
    sessionStorage.clear();
    localStorage.clear();
    this.msalService.logoutRedirect();
  }

  editProfileFlow() {
    let editProfileFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.editProfile.authority,
    };
    this.msalService.loginRedirect(editProfileFlowRequest);
  }

  updateEmailFlow() {
    let updateEmailFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.updateEmail.authority,
    };
    this.msalService.loginRedirect(updateEmailFlowRequest);
  }

  addOrgFlow() {
    let addOrgFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.addOrg.authority,
    };
    this.msalService.loginRedirect(addOrgFlowRequest);
  }

  resetPassFLow(){
    let ressetFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.resetPassword.authority,
    };
    this.msalService.loginRedirect(ressetFlowRequest);
  }

  addRoleFlow(){
    let addRoleFlowRequest = {
      scopes: ["openid"],
      authority: b2cPolicies.authorities.addRole.authority,
    };
    this.msalService.loginRedirect(addRoleFlowRequest);
  }


  checkActiveAccount(){
    let accounts = this.msalService.instance.getAllAccounts();
    if(accounts.length === 0 || accounts == null ||  accounts === undefined){
      this.login();
      return;
    }
    this.msalService.instance.setActiveAccount(accounts[0]);
    this.setActiveProfile();
  }

  setActiveAccount() {

   // let activeAccount = this.authService.instance.getActiveAccount();
    if (this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.setActiveProfile();
    }else{
      //this.login();
    }

  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  setActiveProfile(){
    this.getUserProfile();
    this.navigateToLandings();
  }





  getUserProfile(): IProfileData{
    this._loggingSvc._appInsights.trackTrace({message: 'Auth Service getUserProfile'});
    if(this._userProfile === undefined || this._userProfile === null){
      this._loggingSvc._appInsights.trackTrace({message: 'Auth Service getUserProfile inside if'});
        let tempsess = sessionStorage.getItem('__wcat.000.sss');
        if(tempsess ===undefined || tempsess === null ){
          var companyName = "", ean = "", sub = "", FirstName="", LastName = "", WCATRoleId = "";
          this._loggingSvc._appInsights.trackTrace({ message: 'Auth Service getUserProfile before getActiveAccount' });
          var claims: any = this.msalService.instance.getActiveAccount()?.idTokenClaims;
          this._loggingSvc._appInsights.trackTrace({ message: 'Auth Service getUserProfile after getActiveAccount' });
          var orgsStr: string = (claims['WCATOrgRoleId'] === undefined ||  claims['WCATOrgRoleId'] === null||  claims['WCATOrgRoleId'] == '') ? "0" : claims['WCATOrgRoleId'];
          var orgsArr = orgsStr.split("^");
          var authEmpRoleIdArr: AuthEmpRoleId[] = [];
          if(orgsArr.length > 0){
            orgsArr.forEach((itm => {
              authEmpRoleIdArr.push(new AuthEmpRoleId(itm));
            }));
            this._selwCATOrgRoleId = "0"; //orgsArr[0];
          }else{
            authEmpRoleIdArr.push(new AuthEmpRoleId("0"));
            this._selwCATOrgRoleId = "0";
          }


          this._wCATRoleType = claims['WCATRoleType'];
          WCATRoleId = claims['WCATRoleId'];
          sub = claims['sub'];
          FirstName = claims['FirstName'];
          LastName = claims['LastName'];
          this._claimBase =  new ClaimBase(this._wCATRoleType!, WCATRoleId, this._selwCATOrgRoleId, authEmpRoleIdArr);
          var userData = new ProfileData(companyName, ean, sub, FirstName, LastName, this._claimBase, new Date(),'');
          this._userProfile = userData;
          var seruser = JSON.stringify(userData);
          sessionStorage.setItem('__wcat.000.sss', seruser);
        }else{
          this._userProfile = JSON.parse(tempsess!);
          this._selwCATOrgRoleId = this._userProfile?.claimBase.wCATOrgRoleId;
          this._wCATRoleType = this._userProfile?.claimBase.wCATRoleType;
        }
      }
      this._loggingSvc.setUserContext(this._userProfile!);
      return this._userProfile!;
  }

  updateProfileEmployers(employers: IIntEmployersForContact[]): void{
    var prfEmployers = this._userProfile?.claimBase.authEmpRoleID;
    let finalEmps = prfEmployers?.filter(exOrg => employers.some(emp => emp.employerRoleID == Number(exOrg.wcatEmpRoleID)));

    //console.log('join Orgs');
    //console.log(JSON.stringify(prfEmployers));
    //console.log(JSON.stringify(employers));
    //console.log(JSON.stringify(finalEmps));

    if(finalEmps === undefined || finalEmps == null || finalEmps.length == 0)
      return;

    this._userProfile!.claimBase.authEmpRoleID = finalEmps!;
    this._userProfile!.claimBase.wCATOrgRoleId = finalEmps![0].wcatEmpRoleID;
    var seruser = JSON.stringify(this._userProfile);
    sessionStorage.setItem('__wcat.000.sss', seruser);
  }



  updateEmployers(employers: IIntEmployersForContact[], selIndex: number): void{
    if(employers == null|| employers === undefined || employers.length == 0)
      return;

    this._userProfile!.employerName = employers[selIndex].employerName!;
    this._userProfile!.ean = employers[selIndex].ean!;

    var empOrg : Array<AuthEmpRoleId> = [];
    for(var i= 0; i < employers.length; i++){
      empOrg.push(new AuthEmpRoleId(employers[i].employerRoleID.toString()));
    }

    this._userProfile!.claimBase!.authEmpRoleID = [];
    this._userProfile!.claimBase!.wCATOrgRoleId = employers[selIndex].employerRoleID.toString();
    this._userProfile!.claimBase!.authEmpRoleID.push(...empOrg);
    this._claimBase = this._userProfile!.claimBase;
    this._selwCATOrgRoleId = this._userProfile?.claimBase.wCATOrgRoleId;
    this._wCATRoleType = this._userProfile!.claimBase.wCATRoleType;


    var seruser = JSON.stringify(this._userProfile);
    sessionStorage.setItem('__wcat.000.sss', seruser);
    localStorage.setItem('__lggd.sss', this._claimBase.wCATRoleId);

    addEventListener('storage', this.handleEvent.bind(this),false);
  }


  updateWorkers(): void{
    var seruser = JSON.stringify(this._userProfile);
    sessionStorage.setItem('__wcat.0001.sss', seruser);
    localStorage.setItem('__lggd1.sss', "worker");

    addEventListener('storage', this.handleWorkerEvent.bind(this),false);
  }




  handleEvent(event: any){
    var lgd = localStorage.getItem('__lggd.sss');
    if (lgd === null) {
      this.logoutFlow();
    }
  }

  handleWorkerEvent(event: any){
    var lgd = localStorage.getItem('__lggd1.sss');
    if (lgd === null) {
      this.logoutFlow();
    }
  }



  navigateToLandings(): void{
    //checks profile loaded
    this.getUserProfile();
    let lastNav = this._globalSupport.getNavData();
    if(lastNav == null || lastNav === undefined){
      switch(this._wCATRoleType){
        case'RL1':
          this._router.navigate(['worker-landing']);
        break;
        case'RL2':
          this._loggingSvc._appInsights.trackTrace({message: 'Auth Service - navigateToLandings'});
          this._router.navigate(['employer-landing']);
        break;
        case'RL13':
          this._router.navigate(['representative-landing']);
        break;
        default:
            //this.logoutFlow();
          break;
      }
    }else{
      switch(lastNav.lastVisited){
        case 'APPLDET':
          this._router.navigate(['appeal-det']);
        break;
        case'SUBMSS':
          this._router.navigate(['appeal-sub']);
        break;
        case'SUBMSSNOT':
          this._router.navigate(['appeal-not']);
        break;
        default:
          this._router.navigate(['home']);
          break;
      }
    }
  }

  redirectToLandings(): void{
    this.getUserProfile();
    switch(this._wCATRoleType){
      case'RL1':
        this._router.navigate(['worker-landing']);
      break;
      case'RL2':
      this._loggingSvc._appInsights.trackTrace({message: 'Auth Service - redirectToLandings'});
        this._router.navigate(['employer-landing']);
      break;
      case'RL13':
        this._router.navigate(['representative-landing']);
      break;
      default:
         //this.logoutFlow();
        break;
    }
  }

}
