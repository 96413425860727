import { Injectable } from '@angular/core';
import { ApplicationInsights, Exception } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AppInitService } from '../config/app-init.service';
import { IProfileData } from '../models/IProfileData';

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {

  _appInsights: ApplicationInsights;

  constructor(private _inicfg: AppInitService) {
    this._appInsights = new ApplicationInsights({
      config: {
        //instrumentationKey: _inicfg.config?.APP_INSIGHT_INST_KEY,
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true,
        enableDebug: false, // disable throw exception when logging
        enableCorsCorrelation: true
      }
    });
    this._appInsights.loadAppInsights();
    this._appInsights.addTelemetryInitializer(() => true); //
    this._appInsights.trackTrace({message: 'Initialize AppInsights'});
    //this._appInsights.trackException({exception: new Error('test')});
            
   }

   logPageView(name?: string, url?: string) {
    this._appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  public StartTrackPage(name?: string) {
    this._appInsights.startTrackPage(name);
  }



  logEvent(name: string, properties?: { [key: string]: any }) {
    this._appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this._appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this._appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    console.log(exception);
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this._appInsights.trackTrace({ message: message}, properties);
  }


  // Telemetry Initializers always run right before Telemetry is sent to app insights. This allows for injection of information such as username
    // as well as filtering. It acts as a middleware of sorts.
    // More information can be found at the following URLs:
    //     https://docs.microsoft.com/en-us/azure/azure-monitor/app/api-filtering-sampling#javascript-telemetry-initializers
    //     https://dev.azure.com/wcbbc/ART-Claims/_sprints/taskboard/Team%201/ART-Claims/Iteration%20107?workitem=315529
    // Application Insights should record the IDAM userID along with obscured CCN.
    public setUserContext(profile: IProfileData) {
      this._appInsights.addTelemetryInitializer(function (envelope) {
        envelope.ext!.user.id = profile.wcatUserEmail;
        // To set custom properties:
        if (envelope.data) {

          if (envelope.baseData) {
            var telemetryItem = envelope.baseData;
            telemetryItem.properties = telemetryItem.properties || {};
            telemetryItem.properties["wcatUserEmail"] = profile.wcatUserEmail;
            telemetryItem.properties["wCATRoleType"] = profile.claimBase.wCATRoleType;
            telemetryItem.properties["wCATRoleId"] = profile.claimBase.wCATOrgRoleId;
            telemetryItem.properties["wCATOrgRoleId"] = profile.claimBase.wCATOrgRoleId;
            }
          }
        return true;
      });

    }

}
