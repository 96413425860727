import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { UploadFileData } from "../../../services/models/UploadFileData";



@Component({
  selector: 'app-dragndroplist',
  templateUrl: './dragndroplist.component.html',
  styleUrls: ['./dragndroplist.component.css']
})
export class DragndroplistComponent implements OnInit {

  @Input() uploadItems: UploadFileData[] = [];
  @Output() uploadItemsOut : EventEmitter<UploadFileData[]> = new EventEmitter();
  @Output() showFileOut: EventEmitter<UploadFileData> = new EventEmitter();
  @Input() src: string | undefined;

  drop(event: CdkDragDrop<string[]>) {
    this.uploadItems[event.previousIndex].index = event.currentIndex;

    for(let i=0; i<this.uploadItems.length; i++){
      if(i === event.previousIndex)
        continue;
        this.uploadItems[i].index = i + 1;
    }

    moveItemInArray(this.uploadItems!, event.previousIndex, event.currentIndex);
    this.uploadItemsOut.emit(this.uploadItems);
  }
  constructor() {
   }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges):void{
    for (let propName in changes) {
      if(propName === 'uploadItems'){

      }
    }
  }

  remove(name: string){
    let idx = this.uploadItems!.findIndex(f=> f.fileName === name);
    this.uploadItems?.splice(idx, 1);
    this.uploadItemsOut.emit(this.uploadItems);
  }
  showSelectedFile(item: UploadFileData){
    this.showFileOut.emit(item);
  }

  linkDisabled(item: UploadFileData): boolean{
    let ext = item.fileName.split('.').pop()?.toLocaleLowerCase()
    if(ext === 'docx' || ext === 'rtf' || ext === 'doc')
      return false;

    return true;
  }

}
