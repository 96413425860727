<div class="wrapper" *ngIf="uploadItems!.length > 0">
      <div cdkDropList class="item-list" (cdkDropListDropped)="drop($event)">
        <div class="list-block" style="background: url(assets/shared/images/sort-Icon.svg)  no-repeat; background-position: 10px;" *ngFor="let item of uploadItems" cdkDrag>
         <div class="img-typ" [ngStyle]="{'background' :
          item.file!.name.toLowerCase().split('.').pop() === 'pdf' ? 'url(assets/shared/images/pdf-Icon.svg) no-repeat 40px' :
          item.file!.name.toLowerCase().split('.').pop() === 'txt' ? 'url(assets/shared/images/text-Icon.svg) no-repeat 40px' :
          (item.file!.name.toLowerCase().split('.').pop() === 'doc' ||  item.file!.name.toLowerCase().split('.').pop() === 'docx'  ||  item.file!.name.toLowerCase().split('.').pop() === 'rtf')? 'url(assets/shared/images/word-Icon.svg) no-repeat 40px':
          (item.file!.name.toLowerCase().split('.').pop() === 'img' || item.file!.name.toLowerCase().split('.').pop() === 'png' || item.file!.name.toLowerCase().split('.').pop() === 'jpg' || item.file!.name.toLowerCase().split('.').pop() === 'jpeg')? 'url(assets/shared/images/image-Icon.svg) no-repeat 40px': ''}">
          <div class="itm-dta"><div class="txtlbl" title="{{item.fileName}}">{{item.fileName}}</div></div>
          <mat-progress-bar class="pgbr" mode="determinate" *ngIf="item.uploadProgress > 0 && item.uploadProgress < 100" value="{{item.uploadProgress}}"></mat-progress-bar>
          <div  *ngIf="item.uploadProgress === -1 || item.uploadProgress === 100" [ngClass]="item.uploadProgress === -1 ? 'errmsg':'succss'">{{item.message}}</div>
        </div>
          <div class="d-flex lnks" #divfileControls [ngClass]="src == '2STP' ? 'vis-no':'vis-yes'">
            <!-- <a class="lnk-grn itm" aria-current="page" *ngIf="linkDisabled(item)" (click)="showSelectedFile(item)">View</a>| --><a *ngIf="item.uploadProgress === 0" class="lnk-grn itm" aria-current="page" (click)="remove(item.file!.name)">Remove</a>
          </div>
          <div #divprogressbar>
        </div>
        </div>
      </div>
</div>
