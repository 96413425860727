
<nav class="nav sub-nav shadow-sm bg-white flex-nowrap">

  <ng-container *ngFor="let item of tracks; index as i;">

    <ng-template [ngIf]="i < tracks.length-1" [ngIfElse]="lastTemplate">
      <a class="nav-link initial lnk-grn" aria-current="page" routerLink="/{{item.routePath}}" [ngClass]="{'disabled': !item.active}">{{ item.breadCrumbLabel }}</a>
      <span class="nav-link disabled spacer">></span>
    </ng-template>
    <ng-template #lastTemplate>
      <a class="nav-link disabled crumb" href="#" tabindex="-1" aria-disabled="true">{{ item.breadCrumbLabel }}</a>
    </ng-template>

  </ng-container>


</nav>

