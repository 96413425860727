<h3 mat-dialog-title class="dlgttl">Document: {{data.fileName}}</h3>
<mat-dialog-content >

  <img  [src]="datasrc" class="vwr" *ngIf="filetype =='jpg' || filetype ==='png' || filetype ==='jpeg'" alt="" />

  <div>
    <iframe [src]="datasrc" height="200" width="100%" *ngIf="filetype ==='docx' || filetype ==='doc' || filetype ==='rtf'" frameborder="0"></iframe>
  </div>
<div class="vwrContainer">
<div *ngIf="_dispItem?.mimeType! == 'application/pdf'"  class="vwr">
  <ngx-extended-pdf-viewer [base64Src]="datasrc"
          [src]="datasrc"
          [textLayer]="true"
          [useBrowserLocale]="true"
          [showPagingButtons]="true"
          [showDownloadButton]="false"
          [showBookmarkButton]="false"
          [showPropertiesButton]="false"
          [showPrintButton]="true"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
          [showSidebarButton]="false"
          [sidebarVisible]="false"
          [showZoomButtons]="true"
          [sidebarVisible]="false"
          [showSecondaryToolbarButton]="true"
          [mobileFriendlyZoom]="zoom"
          [showHandToolButton]="true"
          [showOpenFileButton]="false" class="vwr">
   </ngx-extended-pdf-viewer>
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
<span style="margin-bottom: 0px;"> <a class="lnk-grn  p-4" aria-current="page" mat-dialog-close>Close</a></span>
</mat-dialog-actions>
