export class ActTracking{

  partitionKey: string;
  rowKey: string;
  timestamp: Date;
  userId: string;
  action: string;
  actionTags: string;
  actionType: string;
  actionCategory: string;
  sequence: number;
  itemId: string;
  itemType: string;
  itemCategory: string;
  itemName: string;
  itemTags: string;

  constructor(partitionKey: string,
              rowKey: string,
              timestamp: Date,
              userId: string,
              action: string,
              actionTags: string,
              actionType: string,
              actionCategory: string,
              sequence: number,
              itemId: string,
              itemType: string,
              itemCategory: string,
              itemName: string,
              itemTags: string){
      this.partitionKey = partitionKey;
      this.rowKey = rowKey;
      this.timestamp = timestamp;
      this.userId = userId;
      this.action = action;
      this.actionTags = actionTags;
      this.actionType = actionType;
      this.actionCategory = actionCategory;
      this.sequence = sequence;
      this.itemId = itemId;
      this.itemType = itemType;
      this.itemCategory = itemCategory;
      this.itemName = itemName;
      this.itemTags = itemTags;
  }
}
