export class submissionData{

  appealCaseID: number;
  respDocumentType: string;
  wcatCasePartRoleId: number;
  appealNumber: string;
  submissionType: string;
  submittingText?: string;


  constructor(appealCaseID: number,
              respDocumentType: string,
              wcatCasePartRoleId: number,
              appealNumber: string,
              submissionType: string,
              submittingText?: string){

      this.appealCaseID = appealCaseID;
      this.respDocumentType = respDocumentType;
      this.wcatCasePartRoleId = wcatCasePartRoleId;
      this.appealNumber = appealNumber;
      this.submissionType = submissionType;
      this.submittingText = submittingText;
  }
}
