import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { IProfileData } from "../../services/models/IProfileData";
import { Appeal001Service } from "../../services/appeal/appeal001.service";
import { Profile001Service } from "../../services/profile/profile001.service";
import { SubmissionsService } from "../../services/appeal/submissions.service";
import { GlobalSupport } from '../../services/shared/global-support';
import { AuthService } from 'src/app/services/shared/auth.service';
import { INavData } from 'src/app/services/models/INavData';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { GlobalDataService } from 'src/app/services/shared/global-data.service';
import { WcatParameterService } from 'src/app/services/wcat-parameter.service';




@Component({
  selector: 'app-appeal-nthg',
  templateUrl: './appeal-nthg.component.html',
  styleUrls: ['./appeal-nthg.component.css']
})
export class AppealNthgComponent implements OnInit {
  _userData: IProfileData | undefined;
  _navData: INavData| undefined;
  _appealNo : string | undefined;
  _instructions: string = '';

  constructor(private _profsvc: Profile001Service,
              private titleService: Title,
              private _applsvc: Appeal001Service,
              private _submsssvc: SubmissionsService,
              private _notifyService : NotificationService,
              private _activatedRoute : ActivatedRoute,
              private _router : Router,
              private _globalSupport : GlobalSupport,
              private _authSrv: AuthService,
              private _globaldataSrv: GlobalDataService,
              private _wcatParameterService: WcatParameterService) {
      this._userData = this._authSrv.getUserProfile();
      this._navData = this._globalSupport.getNavData();
      this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSSNOT',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber, this._navData?.submissionType,this._navData?.reference, Number(this._userData!.claimBase!.wCATOrgRoleId));
      this._appealNo = this._navData!.appealNo;

      this._globalSupport.addTracking(this._activatedRoute);

    }

  ngOnInit(): void {
    this.titleService.setTitle("WCAT Online Service - Nothing to submit");
    this._instructions = this._globaldataSrv.noSubmissionInst.Item(this._navData?.origDocumentType!);
  }

  cancelNothing(){
    //this._globalSupport.removeNavEntryVisited("appeal-not");
    //this._router.navigate(['appeal-det']);
    if (((this._wcatParameterService.submissionType)) && (this._wcatParameterService.submissionType!.length > 0)){
      //this._navData!.submissionType = this._wcatParamService.submissionType;
      //TODO Check this logic. If I am canceling from nothing to submit, 
      this._navData = this._globalSupport.getNavData();
      this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,this._wcatParameterService.submissionType, this._navData?.reference, Number(this._userData!.claimBase!.wCATOrgRoleId));
      this._wcatParameterService.submissionType = '';
    }
    var lstEntry = this._globalSupport.popLastVisitedEntry();
    this._router.navigate([lstEntry!.routePath!]);
  }

  confirmNothing(){
     this._submsssvc.confirmNothingToSubmit(this._navData?.appealCaseID!, this._navData?.respDocumentType!, this._navData?.wcatCasePartRoleID!, this._navData?.appealNo!)
     .subscribe(response =>{
       if(response.submissionSuccessful){
        this._notifyService.showSuccess('Submission Successful', 'Submission Received');
        this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSSNOT',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber, this._navData?.submissionType,this._navData?.reference, this._navData?.wcatEmpRoleID);
         this._router.navigate(['appeal-notcnfm'], { skipLocationChange: true});
       }else{
        this._notifyService.showWarning('We could not receive your submission. Please try again.','Something went wrong');
       }
     },
     error =>{
      this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
     }
    );
  }

}
