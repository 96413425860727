export class searchData{
  searchText: string;
  orgIds: string[];

  constructor(searchText: string, orgIds: string[]){
    this.searchText = searchText;
    this.orgIds = orgIds;
  }

}
