import { IProfileData } from "../../services/models/IProfileData";
import { IIntAppealShort } from "./IIntAppealShort";


export interface IAppealHeader{
  userData: IProfileData;
  appealState: string;
  appealStateCount: number;
  appeals: IIntAppealShort[];
}


export class AppealHeader implements IAppealHeader{
  userData: IProfileData;
  appealState: string;
  appealStateCount: number;
  appeals: IIntAppealShort[];

  constructor(userData: IProfileData,
            appealState: string,
            appeals: IIntAppealShort[]){
    this.userData = userData;
    this.appealState = appealState;
    this.appealStateCount = appeals === null ? 0 :appeals.length;
    this.appeals = appeals;
  }

}
