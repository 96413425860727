import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import{ IConfigData } from '../models/IConfigData';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  private _configData : IConfigData | undefined;
  private _configPath : string = `/assets/config/app-settings.${environment.name}.json`;
  private _http : HttpClient;

  constructor(private http : HttpClient) {
    this._http = http;
  }

  async Init(): Promise<IConfigData>{
    try{
      const response = await this._http.get<IConfigData>(this._configPath).toPromise();
      this._configData = response;
      return this._configData!;
    }catch(err){
      return Promise.reject(err);
    }
  }

  get config(): IConfigData | undefined{
    return this._configData;
  }

}
