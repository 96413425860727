import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { AppInitService } from "../../services/config/app-init.service";
import { Profile001Service } from "../../services/profile/profile001.service";
import { IClaimBase } from '../models/IClaimBase';
import { IIntAppealsTrf } from '../models/IIntAppealsTrf';
import { IIntAppeal } from '../models/IIntAppeal';
import { IIntAppealDocument } from '../models/IIntAppealDocument';
import { IIntDocumentContent } from '../models/IIntDocumentContent';
import { AppInsightService } from '../shared/app-insight.service';
import { searchData } from '../models/SearchData';
import { selectedData } from '../models/selectedData';


@Injectable({
  providedIn: 'root'
})
export class Appeal001Service {

  constructor(private _inicfg: AppInitService,
              private _profsvc: Profile001Service,
              private _http: HttpClient,
              private _appinsg: AppInsightService) {

               }

  httpOptions = { headers: new HttpHeaders({
                                'Content-Type': 'application/json'
                                })
                };


  getLandingAppeals(wCATOrgRoleId: string){
    let targetUrl = `${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/appeal/landingappeals`;
    let payload = new selectedData(wCATOrgRoleId);
    this._appinsg.logEvent("getLandingAppeals", { 'url':targetUrl });
      return this._http.post<IIntAppealsTrf>(targetUrl, JSON.stringify(payload),this.httpOptions);
  }

  getAppealDet(appealNo: string): Observable<IIntAppeal>{
    let targetUrl = `${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/appealDetails/detail/${appealNo}`;
    this._appinsg.logEvent("getAppealDet", { 'url':targetUrl });
      return this._http.post<IIntAppeal>(targetUrl, this.httpOptions);
  }

  getAppealDetByCaseId(appealNo: string, caseId: number): Observable<IIntAppeal>{
    return this._http.post<IIntAppeal>(`${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/appealDetails/detail/${appealNo}/${caseId}`, this.httpOptions);
}

  getDocuments(appealCaseID: number): Observable<IIntAppealDocument[]>{
    return this._http.post<IIntAppealDocument[]>(`${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/appealDetails/docs/${appealCaseID}`, this.httpOptions);
  }

  getDocumentContent(documentID: number, appealCaseID: number): Observable<IIntDocumentContent>{
    return this._http.post<IIntDocumentContent>(`${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/appealDetails/content/${appealCaseID}/${documentID}`, this.httpOptions);
  }

  getSearchAppeals(searchTxt: string, wcatEmpRoleIDs: string[]): Observable<IIntAppeal[]>{
    let payLoad  = new searchData(searchTxt, wcatEmpRoleIDs);
    return this._http.post<IIntAppeal[]>(`${this._inicfg.config?.ENDPOINT_BASE_NETCORE}/wcat/appeal/search`,JSON.stringify(payLoad), this.httpOptions);
  }





  errorHandler(error: any){
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //window.alert(errorMessage);
    return throwError(errorMessage);
  }



}
