import { StringUtils } from "@azure/msal-browser";

export interface INavData{
  sequence: number;
  origin: string | undefined;
  lastVisited: string | undefined;
  appealNo: string | undefined;
  origDocumentType: string | undefined;
  respDocumentType: string | undefined;
  wcatCasePartRoleID: number | undefined;
  appealCaseID: number | undefined;
  claimNumber: string | undefined;
  submissionType: string | undefined;
  reference: string | undefined;
  wcatEmpRoleID: number| undefined;
}


export class NavData implements INavData{
  sequence: number;
  origin: string | undefined;
  lastVisited: string | undefined;
  appealNo: string | undefined;
  origDocumentType: string | undefined;
  respDocumentType: string | undefined;
  wcatCasePartRoleID: number | undefined;
  appealCaseID: number | undefined;
  claimNumber: string | undefined;
  submissionType: string | undefined;
  reference: string | undefined;
  wcatEmpRoleID: number| undefined;

  constructor(sequence: number,
              origin?: string,
              lastVisited?: string,
              appealno?: string,
              origDocumentType?: string,
              respDocumentType?: string,
              wcatCasePartRoleID?: number,
              appealCaseID?: number,
              claimNumber?:string,
              submissionType?: string,
              reference?: string,
              wcatEmpRoleID?: number){
    this.sequence = sequence;
    this.origin = origin;
    this.lastVisited = lastVisited;
    this.appealNo = appealno;
    this.origDocumentType = origDocumentType;
    this.respDocumentType = respDocumentType;
    this.wcatCasePartRoleID = wcatCasePartRoleID;
    this.appealCaseID = appealCaseID;
    this.claimNumber = claimNumber;
    this.submissionType = submissionType;
    this.reference = reference;
    this.wcatEmpRoleID = wcatEmpRoleID;
  }
}
