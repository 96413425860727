<app-sub-header></app-sub-header>


<div class="apl-det pb-5">
  <div class="" id="">
    <h1 class="display-6" id="usr-fullname">{{ this._userData?.userFirstName! | capitalCase }} {{ this._userData?.userLastName! | capitalCase}}</h1>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL2'">{{ this._userData?.employerName }}</h2>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL13'">{{ this._userData?.clientName }}</h2>
    <h4 class="sub-dis"><span id="usr-id"></span></h4>
  </div>
  <hr class="style1">

  <div >
    <app-appealsearch [origin]="_origin" [_userData]="_userData" [_wcatEmpRoleID]="this._navData?.wcatEmpRoleID!"></app-appealsearch>
  </div>



    <div class="" id="str-apl-cntr">
      <h4><span id="apl-nbr" >Appeal number: {{ this._appealNo }} </span></h4>

        <p>Your appeal status is: <b>{{ _appealStatus }}</b>.   <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/check-the-status-of-an-appeal/" target="_blank">Find out what an appeal status means.<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>
        </p>
        <p></p>

        <div *ngIf="isActiveAppeal()">
          <div *ngIf="_remainders">
              <h4>What you need to do by: {{ this._appealDet?.submissionLinks![0].deadlineDate | date: "longDate" }}</h4>


              <div *ngFor="let item of _appealDet?.submissionLinks">
                <div (click)="navMakeSub( _appealDet!, item)" class="pt-2"><a class="initial lnk-grn" aria-current="page"  matTooltip="{{item.tooltipTxt}}" matTooltipClass="custom-tooltip">{{item.labelText}}</a></div>
                <div (click)="navMakeNoSub(_appealDet!,item)" class="pt-2"><a class="initial lnk-grn" aria-current="page"  matTooltip="Nothing to submit." matTooltipClass="custom-tooltip">Confirm that you have nothing to submit</a></div>
              </div>

          </div>
          <h4><span id="apl-nbr" class="pt-2">Things you can do</span></h4>

          <div class="pt-2" *ngIf="participantStatus">
            <a class="initial lnk-grn" aria-current="page" (click)="genericSubmt()" matTooltip="If you need to provide WCAT with other information (such as a request for withdrawal or to ask for additional time to provide a submission), use this link." matTooltipClass="custom-tooltip">Provide more information or make a request</a>
          </div>
          <div class="pt-2" *ngIf="!participantStatus">
            <a class="initial lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/forms/" target="_blank" matTooltip="Notify WCAT you wish to participate in the appeal by submitting a Notice of Participation form online." matTooltipClass="custom-tooltip">Submit a Notice of Participation form</a>
          </div>
          <div class="pt-2"><a class="initial lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/forms/form-change-of-name-or-contact-information/" target="_blank">Update your contact information <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></div>

        </div>
    </div>






  <div class="" id="mng-cntr">
    <div class="cntr-clps">
      <div class="pnl-cntr pt-1">

        <mat-card appearance="outlined">
          <mat-card-title>
          </mat-card-title>

          <mat-card-content>
            <div class="sngl-ln " style="margin-right: 10px; text-align: right; cursor: pointer; padding-bottom: 10px;">
              <a class="lnk-grn" aria-current="page"  [routerLink]="" (click)="expandAllPanels()">Expand All</a> | <a class="lnk-grn" aria-current="page"  [routerLink]="" (click)="closeAllPanels()">Collapse All</a>
          </div>

            <div class="d-flex flex-wrap">
            <div class="sub-hdr-lft">

            </div>
          </div>
          <mat-accordion multi="true">
            <mat-expansion-panel (opened)="setPanelIndex(0,'O');" (closed)="setPanelIndex(0,'C')">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div [hidden]="_panelState[0]"><h5>Appeal details</h5></div>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <app-appeal-sc-det [_appealDet]="_appealDet" [_origin]="_origin" [_appealNo]="_appealNo" [_isActive]="isActiveAppeal()" [_clientName]="_detailClientName"></app-appeal-sc-det>
            </mat-expansion-panel>

            <mat-expansion-panel
              (opened)="setPanelIndex(1,'O');"
              (closed)="setPanelIndex(1,'C')">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div [hidden]="_panelState[1]"><h5>How the appeal will proceed</h5></div>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <app-appeal-sc-prc [_appealDet]="_appealDet" [_origin]="_origin" [_appealNo]="_appealNo" [_isFuture]="_isFuture" [_remainders]="_remainders" [_typeOfHearing]="_typeOfHearing" [_locationText]="_locationText" [_typeOfHearingStaticText]="_typeOfHearingStaticText"></app-appeal-sc-prc>
            </mat-expansion-panel>

            <mat-expansion-panel
                (opened)="setPanelIndex(2,'O');"
                (closed)="setPanelIndex(2,'C')">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div [hidden]="_panelState[2]"><h5>Other appeals that are joined to this appeal</h5></div>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <app-appeal-sc-jnd [_joinedAppeals]="_joinedAppeals" [_origin]="_origin" [_appealNo]="_appealNo"></app-appeal-sc-jnd>
            </mat-expansion-panel>

            <mat-expansion-panel
                (opened)="setPanelIndex(3,'O');"
                (closed)="setPanelIndex(3,'C')">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div [hidden]="_panelState[3]"><h5>Appeal parties</h5></div>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <app-appeal-sc-prt [_participants]="_participants" [_origin]="_origin" [_appealNo]="_appealNo"></app-appeal-sc-prt>
            </mat-expansion-panel>

            <mat-expansion-panel (opened)="setPanelIndex(4,'O');" (closed)="setPanelIndex(4,'C')">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div [hidden]="_panelState[4]"><h5>Decision details</h5></div>
                </mat-panel-title>
                <mat-panel-description></mat-panel-description>
              </mat-expansion-panel-header>
              <app-appeal-sc-dsc [_appealDet]="_appealDet" [_origin]="_origin" [_appealNo]="_appealNo"></app-appeal-sc-dsc>
            </mat-expansion-panel>



          </mat-accordion>

          <app-appeal-sc-fls [_documents]="_documents" [_origin]="_origin" [_userData]="_userData" [_appealNo]="_appealNo" (selectedDocument)="selectedDocument($event)"></app-appeal-sc-fls>
          </mat-card-content>



        </mat-card>
      </div>
</div>





