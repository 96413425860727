


export class SiteData{

  type: string;
  routePath: string;
  routeLabel: string;
  breadCrumbLabel: string;
  childSequence: number;
  key: string;
  parentKey: string;
  active: boolean;
  rootPath: string;

  constructor( type: string,
              routePath: string,
              routeLabel: string,
              breadCrumbLabel:string,
              childSequence: number,
              key: string,
              parentKey: string,
              active: boolean,
              rootPath: string){
    this.type = type;
    this.routePath = routePath;
    this.routeLabel = routeLabel;
    this.breadCrumbLabel = breadCrumbLabel;
    this.childSequence = childSequence;
    this.key = key;
    this.parentKey = parentKey;
    this.active = active;
    this.rootPath = rootPath;
  }





}


