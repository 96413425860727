<app-sub-header></app-sub-header>
<div class="apl-det">
  <div class="" id="">
    <h1 class="display-6" id="usr-fullname">{{ this._userData?.userFirstName! | capitalCase }} {{ this._userData?.userLastName! | capitalCase }}</h1>
    <h2 class="display-6" id="usr-fullname">{{ this._userData?.employerName }}</h2>
    <h4 class="sub-dis"><span id="usr-id"></span></h4>
  </div>
  <hr class="ln-1">

  <div >
    <app-appealsearch [origin]="_origin[2]" [_userData]="_userData" [_wcatEmpRoleID]="_wcatEmpRoleID"></app-appealsearch>
  </div>

    <div class="" id="str-apl-cntr">
      <h4><span id="apl-nbr">Update your contact information</span></h4>
      <span>
        <p>Keep your contact information up to date during the appeal process.</p>
        <p class="">Complete the online form: <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/forms/form-change-of-name-or-contact-information/" target="_blank">Change of name or contact information <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></p>
        <p class=""><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/forms/form-authorization-of-representative/" target="_blank">Authorize a representative <img src="../assets/shared/images/navigate-icon.svg" alt="Authorize a representative"></a></p>

        <!-- <h4 class="pt-4"><span></span></h4> -->
      </span>
    </div>






    <div class="pt-1" id="">
      <h4>Manage an appeal</h4>
      <span>
        Look up details for appeals that you are participating in. Right now, WCAT Online Services can only be used for: <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16"
          matTooltip="If you don't see an appeal here, it might be due the following reasons:&#13;
          * The appeal has not been started.&#13;
          * You are not an appellant or respondent involved in the appeal.
             For example, if you were invited as an interested person, instead
             of as the appellant or respondent.&#13;
          * The type of appeal or application is not available online. Right now,
             employers can only see compensation, assessment and occupational
             health and safety appeals online. For other appeals, WCAT will keep
             sending your appeal documents by mail or email."
          matTooltipClass="custom-tooltip">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
</svg></span>
      </span>
      <ul>
        <li>Workers' compensation appeals</li>
        <li>Occupational health and safety appeals</li>
        <li>Assessment appeals</li>
      </ul>
      <p>You can only see details of an appeal you are participating in. If an appeal is not listed here, <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/check-the-status-of-an-appeal/"  target="_blank">check your appeal status online.<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></p>

        <div class="pt-5">

          <mat-card appearance="outlined">
            <mat-card-title>
            </mat-card-title>
            <mat-card-content>

              <div class="org-container">
                <mat-checkbox [(ngModel)]="_myAppealsOnly" (change)="myAppelsChange($event)">My Appeals</mat-checkbox>
                <mat-form-field appearance="fill">
                  <mat-select [formControl]="_orgSelect" (selectionChange)="orgSelected()"  [(ngModel)]="_wcatEmpRoleID">
                    <mat-option *ngFor="let org of _employers" [value]="org.employerRoleID">{{org.employerName}}</mat-option>
                  </mat-select>
                </mat-form-field>

              </div>

              <ngx-spinner
            bdColor="rgba(51,51,51,0.8)"
            size="medium"
            color="#fff"
            type="ball-scale-multiple"
            [fullScreen] = "false"><p style="color: white; margin-top: 60px;" > Loading... </p></ngx-spinner>
              <mat-tab-group>
                <mat-tab label="Appeals in progress ({{ this._appealsInProgress?.appealStateCount ?? 0}})"><app-appeal-mngt [appealheaderData]="_appealsInProgress" [origin]="_origin[0]"></app-appeal-mngt></mat-tab>
                <mat-tab label="Past appeals ({{ this._appealsPast?.appealStateCount ?? 0}})"><app-appeal-mngt [appealheaderData]="_appealsPast" [origin]="_origin[1]"></app-appeal-mngt></mat-tab>
              </mat-tab-group>
            </mat-card-content>
            <mat-card-actions>
            </mat-card-actions>
          </mat-card>
        </div>
      <div class="btn-cntr">

      </div>


    </div>



