<div class="d-flex flex-wrap">
  <div>
    <h4></h4>
    <span></span>
  </div>
  <div class="pt-4">

    <h4>Files, letters and documents</h4>
    <p><span class="desc-bx"> All communication and submissions for this appeal have been created by the Workers' Compensation Appeal Tribunal (WCAT) or any of the participating parties. Everyone participating in the appeal can see these files.</span></p>
</div>
</div>

<div class="pt-3" style="display: none;">
  <section [formGroup]="_docFilter">
  <div class="fltr-box">
    <div class="row align-items-start">
      <div class="col col-md-1 pl-5">
        <div class="">&nbsp;&nbsp;Filter</div>
      </div>
      <div class="col col-xs">
        <mat-checkbox formControlName="Letters" class="px-3" (change)="updCheck()">Letters</mat-checkbox>
      </div>
      <div class="col col-xs">
        <mat-checkbox formControlName="Decisions" class="px-3" (change)="updCheck()">Decisions</mat-checkbox>
      </div>
      <div class="col col-xs">
        <mat-checkbox formControlName="Submissions"class="px-3" (change)="updCheck()">Submissions</mat-checkbox>
      </div>
      <div class="col col-xs">
        <mat-checkbox formControlName="Emails" class="px-3" (change)="updCheck()">Emails</mat-checkbox>
      </div>
    </div>
  </div>
</section>
 <span>Showing [{{_documents?.length}}] items.</span>
</div>

<div class="">
  <div id="" class="main-cnt rnd-01 m-3">
    <div class="tbl-container">
      <ngx-spinner
            bdColor="rgba(51,51,51,0.8)"
            size="medium"
            color="#fff"
            type="ball-scale-multiple"
            [fullScreen] = "false"><p style="color: white; margin-top: 60px;" > Loading... </p></ngx-spinner>
      <table mat-table [dataSource]="_datasource!" matSort (matSortChange)="announceSortChange($event)">

        <ng-container matColumnDef="docstate">
          <th mat-header-cell *matHeaderCellDef><span class="text-nowrap clm-0"></span></th>
          <td mat-cell *matCellDef="let element"><div class="clm-0 dotSpc" *ngIf="!element.viewed" ><img alt="" src="../assets/shared/images/liteDot.png"  class="img-fluid" /></div></td>
        </ng-container>

        <ng-container matColumnDef="documentType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by document type"><span class="text-nowrap">DOCUMENT OR FILE TYPE</span></th>
          <td mat-cell *matCellDef="let element" class="cl01"><div class="clm-a"><a class="lnk-grn" aria-current="page" [routerLink]="" (click)="showFile(element);" >{{element.documentTypeDesc}}</a>  </div></td>
        </ng-container>

        <ng-container matColumnDef="submissionDate">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by document date"> DATE </th>
          <td mat-cell *matCellDef="let element" class="cl02"><div class="text-nowrap p-2"> {{element.documentDate | date: "mediumDate"}} </div></td>
        </ng-container>

        <ng-container matColumnDef="about">
          <th mat-header-cell *matHeaderCellDef> COMMENT </th>
          <td mat-cell *matCellDef="let element">

            <div class="cnt-det">
              <div class="text-nowrap"> <div class="semihdr inline"></div> <div class="cntn-rw inline">{{element.comment }}</div></div><br/>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="refresh">
          <th mat-header-cell *matHeaderCellDef><button type="button" (click)="refreshLocal()"> <img alt="" src="../assets/shared/images/refresh.png" height ="36" width="36" /></button> </th>
          <td mat-cell *matCellDef="let element">

            <div class="cnt-det">
              <div class="text-nowrap"> <div class="semihdr inline"></div> <div class="cntn-rw inline"></div></div><br/>
            </div>
          </td>
        </ng-container>

        <!--  -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </div>
  </div>
  <mat-paginator
  (page)="handlePageEvent($event)"
  [length]="_documents?.length"
  [pageSize]="pageSize"
  [showFirstLastButtons]="showFirstLastButtons"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  [hidePageSize]="false"
  aria-label="Select page">
  </mat-paginator>
  </div>
