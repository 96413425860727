import { IIntAuthEmpRoleId } from "./IIntAuthEmpRoleId";

export interface IClaimBase{
  wCATRoleType: string;
  wCATRoleId: string;
  wCATOrgRoleId: string;
  authEmpRoleID: IIntAuthEmpRoleId[];
}


export class ClaimBase implements IClaimBase{
  wCATRoleType: string;
  wCATRoleId: string;
  wCATOrgRoleId: string;
  authEmpRoleID: IIntAuthEmpRoleId[];

  constructor(wCATRoleType: string,
              wCATRoleId: string,
              wCATOrgRoleId: string,
              authEmpRoleID: IIntAuthEmpRoleId[]){
    this.wCATRoleType = wCATRoleType;
    this.wCATRoleId = wCATRoleId;
    this.wCATOrgRoleId = wCATOrgRoleId;
    this.authEmpRoleID = authEmpRoleID;

  }

}
