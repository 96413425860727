import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, KeyValueDiffers, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxFileDropComponent, NgxFileDropEntry } from 'ngx-file-drop';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, tap } from 'rxjs';
import { Appeal001Service } from 'src/app/services/appeal/appeal001.service';
import { SubmissionsService } from 'src/app/services/appeal/submissions.service';
import { IIntAppeal } from 'src/app/services/models/IIntAppeal';
import { INavData } from 'src/app/services/models/INavData';
import { IProfileData } from 'src/app/services/models/IProfileData';
import { UploadFileData } from 'src/app/services/models/UploadFileData';
import { AuthService } from 'src/app/services/shared/auth.service';
import { GlobalSupport } from 'src/app/services/shared/global-support';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { ShowfileDialogComponent } from '../shared/dialogs/showfile-dialog.component';
import { WcatParameterService } from 'src/app/services/wcat-parameter.service';

@Component({
  selector: 'app-appeal-sub',
  templateUrl: './appeal-sub.component.html',
  styleUrls: ['./appeal-sub.component.css']
})
export class AppealSubComponent implements OnInit {

  @ViewChild(NgxFileDropComponent) _ngxdrop: NgxFileDropComponent | undefined;
  @ViewChild("inputArea") inputArea: ElementRef | undefined;

  _fstFrmGrp!: UntypedFormGroup;
  _scdFrmGrp!: UntypedFormGroup;
  _isEditable = false;
  _userData: IProfileData | undefined;
  _appealNo : string | undefined;
  _navData : INavData | undefined;
  _origin: string | undefined;
  _processError: boolean = false;
  _files: NgxFileDropEntry[] = [];
  _uploadItems: UploadFileData[] = [];
  _validating: boolean[] = [false,false, false];
  _totalSize: number = 0;
  _uplading: boolean = false;
  _arrayDiffer: any;
  _appealDet :IIntAppeal | undefined
  _fileDifferMap = new Map<number, any>();
  _fileMap = new Map<number, UploadFileData>();
  _processed = 0;
  _stepper: MatStepper | undefined;
  _textToFile : string ='';
  _submittedDate: Date = new Date();
  _textareText = '';
  //_isEncrypted = false;
  isEncrypted$ = new Subject();
  _showErrorMessage = true;
  _isEncrypted = false;

  constructor(private titleService: Title,
    private _activatedRoute : ActivatedRoute,
    private _router : Router,
    private _globalSupport : GlobalSupport,
    private _submSrvc: SubmissionsService,
    private _formBuilder: UntypedFormBuilder,
    private kvDiffers: KeyValueDiffers,
    public dialog: MatDialog,
    private _notifyService : NotificationService,
    private _authSrv: AuthService,
    private _applsvc: Appeal001Service,
    private _spinner: NgxSpinnerService,
    private _wcatParameterService: WcatParameterService) { }

  ngOnInit(): void {
    this._userData = this._authSrv.getUserProfile();
    this._globalSupport.addTracking(this._activatedRoute);
    this._navData = this._globalSupport.getNavData();

    if (((this._wcatParameterService.submissionType)) && (this._wcatParameterService.submissionType!.length > 0) && (this._navData?.submissionType?.length === 0)) {
      this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,this._wcatParameterService.submissionType, this._navData?.reference, Number(this._userData.claimBase.wCATOrgRoleId));
      this._navData = this._globalSupport.getNavData();
      this._wcatParameterService.submissionType = '';
    }else{
      this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,this._navData?.submissionType, this._navData?.reference, Number(this._userData.claimBase.wCATOrgRoleId));
    }
    //this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,this._navData?.submissionType, this._navData?.reference, Number(this._userData.claimBase.wCATOrgRoleId));
    this._appealNo = this._navData!.appealNo;
    this._origin = this._navData!.origin;
    this._processError = false;
    this.titleService.setTitle("WCAT Online Service - Upload Documents");
    this._fstFrmGrp = this._formBuilder.group({ subDetails01: ['', Validators.required] });
    this._scdFrmGrp = this._formBuilder.group({ subDetails02: ['', Validators.required] });

    this._applsvc.getAppealDetByCaseId(this._navData!.appealNo!, this._navData!.appealCaseID!).subscribe(response =>{
      this._appealDet = response;
      this.extractClientName();
    });
  }

  extractClientName():void{
    if(this._appealDet!.participants!.length > 0){
      let participant = this._appealDet!.participants!.filter(prt => prt.representedBy?.repRoleID ==  Number(this._userData?.claimBase.wCATOrgRoleId));
      if(participant != null && participant.length > 0){
        //this._userData!.clientName = participant[0].companyName ?? (participant[0].firstName + ' ' + participant[0].lastName);
        this._userData!.clientName = participant[0].representedBy.repCompanyName ?? (participant[0].firstName + ' ' + participant[0].lastName);
      }
    }
  }


  public dropped(files: NgxFileDropEntry[]) {
    this._isEncrypted = false;
    this._showErrorMessage = true;
    this._files = files;
    for (const droppedFile of files) {
      this.processOneFile(droppedFile, files);
    }
    return;
  }
  
  public processOneFile(droppedFile: NgxFileDropEntry, files: NgxFileDropEntry[]) {
    
    let reader = new FileReader();
    reader.onload = ()=> {
              var file = new Blob([reader.result!], {type: 'application/pdf'});
              file.text().then(x=> {
                  console.log("isEncrypted", x.includes("Encrypt"))

                  if(x.includes("Encrypt"))
                  {
                    this._isEncrypted = true;
                    this.isEncrypted$.next(true);
                    //error
                    let idx = this._uploadItems!.findIndex(f=> f.fileName === droppedFile.fileEntry.name);
                    this._uploadItems?.splice(idx, 1);
                    if (this._uploadItems?.length > 0){
                        if (this._showErrorMessage){
                          this._notifyService.showInputWarning("Files not accepted","One or more files you tried to upload cannot be accepted. They may be password-protected PDFs or contain Microsoft Word macros. Please remove any password protection or macros and try your upload again. If you continue to have difficulties, you can email your submission to us at appeals@wcat.bc.ca");
                          this._showErrorMessage = false;
                        }
                    }
                    //this.uploadItemsOut.emit(this._uploadItems); 
                    return;
                  }
                  else
                  {
                    if (!this._isEncrypted) {
                      this.isEncrypted$.next(false);
                    }
                  }
              })};

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
           let tmpCheck = this._totalSize + ((file.size/1000)* 0.0009765625);
           //Check File extension for updating pictures name
          let tmpflnm = file.name.replace(/\.[^/.]+$/, "");
          let flext = file.name.split('.').pop()?.toLocaleLowerCase();
          let isJpg = false;
          let allowedext = ['docx', 'pdf', 'jpg', 'jpeg', 'rtf'];

          if(tmpCheck >= 10 || this._uploadItems.length >= 10 || !allowedext.includes(flext!))
          {
            this._validating[2] = true;
            files.pop();
            if (this._showErrorMessage){
                this._notifyService.showInputWarning("Files not accepted","The files you tried to upload cannot be accepted. Please check if your files match our listed upload criteria and try your upload again. If you continue to have difficulties, you can email your submission to us at appeals@wcat.bc.ca.");
                this._showErrorMessage = false;
            }
            return;
          }else{
            this._validating[2] = false;
          }

          if(flext === 'jpg'){
            isJpg = true;
          }

          reader.readAsArrayBuffer(file);
          //this.isEncrypted$.pipe(take(1)).subscribe((isEnc)=>{
          this.isEncrypted$.pipe(take(1)).subscribe((isEnc)=>{
           if (isEnc)
            {
              if (this._showErrorMessage){
              this._notifyService.showInputWarning("Files not accepted","One or more files you tried to upload cannot be accepted. They may be password-protected PDFs or contain Microsoft Word macros. Please remove any password protection or macros and try your upload again. If you continue to have difficulties, you can email your submission to us at appeals@wcat.bc.ca");
              this._showErrorMessage = false;
              }
              //return;
            } 
  
            this._totalSize = tmpCheck;
            let docCode = `${this._navData?.origDocumentType}|${this._navData?.respDocumentType}|${this._navData?.wcatCasePartRoleID}`;
  
            let idx = this._uploadItems.findIndex(f=> f.fileName === file.name);
  
            let flNm = file.name.length > 25 ? (file.name.substring(0,24) + "."+ flext): file.name;
  
            if(idx === -1 || isJpg){
              if (this._uploadItems.length < 10){
                let nwItm = new UploadFileData(this._uploadItems.length,'',docCode, flNm,0,'', false,'','',file);
                this._uploadItems.push(nwItm);
                this._fileDifferMap.set(this._uploadItems.length,this.kvDiffers.find(nwItm).create());
                this._fileMap.set(this._uploadItems.length,nwItm);
              }else
              {
                if (this._showErrorMessage){
                  this._notifyService.showInputWarning("Files not accepted","One or more files you tried to upload cannot be accepted. They may be password-protected PDFs or contain Microsoft Word macros. Please remove any password protection or macros and try your upload again. If you continue to have difficulties, you can email your submission to us at appeals@wcat.bc.ca");
                  this._showErrorMessage = false;
                }
              }
            }else{
              let nwItm = new UploadFileData(idx,'',docCode, flNm,0, '', false,'','',file);
              this._uploadItems[idx] = nwItm;
              this._fileDifferMap.set(idx,this.kvDiffers.find(nwItm).create());
              this._fileMap.set(idx,nwItm);
            }
          });
        });
      }
  }

  refreshSize(){
    let tmpSize: number = 0;
    this._uploadItems.forEach((itm: UploadFileData)=>{
      tmpSize += ((itm.file!.size/1000)* 0.0009765625);
    });
    this._totalSize = tmpSize;

  }



  ngDoCheck() {
    for (let [key, flDiffer] of this._fileDifferMap) {
      let flChanges = flDiffer.diff(this._fileMap.get(key));
      if (flChanges) {
        flChanges.forEachChangedItem((rcrd: any) => {
          if(rcrd.key === 'processed'){
              this._processed++;
            if(this._processed == this._uploadItems.length && this._uploadItems.length > 0){
              setTimeout(() => {
                this._submittedDate = new Date();
                if(this._processError == false){
                  this._stepper!.next();
                }
              },4000);
            }

          }
        });
      }
    }
  }



  cancelSubmission(){

    //this._globalSupport.removeNavEntryVisited("appeal-sub");
    //this._router.navigate(['appeal-det']);
    var lstEntry = this._globalSupport.popLastVisitedEntry();
    this._router.navigate([lstEntry!.routePath!]);

  }

  ngOnChanges(changes: SimpleChanges):void{
    for (let propName in changes) {
      if(propName === 'uploadItems'){

      }
    }
  }

  onNext(stepper: MatStepper, frmIndex: number){
    this._stepper = stepper;
    if(frmIndex == 0){
      this._validating[0] = true;
      if((this._fstFrmGrp.controls['subDetails01'].value.trim().length == 0 && this._uploadItems.length == 0)){
        this._notifyService.showInputWarning("Did you finish your submission?","Please present your case by using the text box or uploading documents and files.");
        this.inputArea!.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
      if((this._fstFrmGrp.controls['subDetails01'].value.trim().length > 0 && this._fstFrmGrp.controls['subDetails01'].value.trim().length < 10 && this._uploadItems.length == 0) || ( this._fstFrmGrp.controls['subDetails01'].value.trim().length > 0 && this._fstFrmGrp.controls['subDetails01'].value.trim().length < 10 && this._uploadItems.length > 0)){
        this._notifyService.showInputWarning("Did you finish your submission?","If you use the text box for your submission, please enter at least 10 characters.");
        this.inputArea!.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
      if((this._fstFrmGrp.controls['subDetails01'].value.trim().length > 2500 && this._uploadItems.length == 0) || (this._fstFrmGrp.controls['subDetails01'].value.trim().length > 2500 && this._uploadItems.length > 0)){
        this._notifyService.showInputWarning("Incorrect Input","Enter between 10 and 2,500 characters");
        this.inputArea!.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
        return;
      }
      if(this._uploadItems.length > 10){
        this._notifyService.showInputWarning("Incorrect Input","You have exceeded the number or allowed files.");
        return;
      }
      this._stepper.next();
      this._scdFrmGrp.controls['subDetails02'].setValue(this._fstFrmGrp.controls['subDetails01'].value);
    }

  }

  editSubmission(stepper: MatStepper, frmIndex: number){
    this._stepper = stepper;
   let tempVal = this._scdFrmGrp.controls['subDetails02'].value;
   this._stepper.reset();
    this._fstFrmGrp.controls['subDetails01'].setValue(tempVal);
  }

  public frmFstError = (controlname: string, errorname: string) =>{
    return  this._validating[0] && (this._scdFrmGrp.controls['subDetails02'].value.trim().length > 0 || this._uploadItems.length > 0);
  };


  public fileOver(event: any){

  }

  public fileLeave(event: any){

  }


  selectFiles(){
    this._ngxdrop?.openFileSelector();
  }


  receiveOutput(data: UploadFileData[]){
    this._uploadItems = data;
    this.refreshSize();
  }



  uploadFiles(): void {

    this._spinner.show();
    this._uplading = true;
    this._textToFile  = this._scdFrmGrp.controls['subDetails02'].value.trim();
    if(this._scdFrmGrp.controls['subDetails02'].value != null ||  (this._scdFrmGrp.controls['subDetails02'].value.trim().length > 10 && this._scdFrmGrp.controls['subDetails02'].value.trim().length < 2500)){

       let filesForm = new FormData();
       if(this._textToFile.length > 10){
        var blob = new Blob([this._textToFile], {type: "text/plain"}); //;charset=utf-8
         filesForm.append('txtcnt', blob, "txt_fle.txt");
       }

       if (this._uploadItems) {
        this._uploadItems.sort((a,b)=> a.index > b.index ? 1: -1);
        for (let i = 0; i < this._uploadItems.length; i++) {
          filesForm.append('ofile', this._uploadItems[i].file!);
        }
       }

       if(this._navData?.submissionType == 'SUBMLNK'){
        this._submSrvc.uploadSubmissions(this._navData?.appealCaseID!, this._navData?.respDocumentType!, this._navData?.wcatCasePartRoleID!, filesForm, this._navData?.appealNo!).subscribe(event => {
          switch(event.type){
            case HttpEventType.Sent:
              //request made
              break;
            case HttpEventType.ResponseHeader:
              //response header received
              break;
              case HttpEventType.UploadProgress:
                //upload progress
                for (let i = 0; i < this._uploadItems.length; i++) {
                this._uploadItems[i].uploadProgress = Math.round(100 * event.loaded / event.total!);
                }
              break;
              case HttpEventType.Response:
                //response received
                for (let i = 0; i < this._uploadItems.length; i++) {
                  this._uploadItems[i].message = "File Uploaded Successfully";
                  this._uploadItems[i].processed = true;
                  this._uploadItems[i].transferOk = true;
                }
                this._spinner.hide();
                this._notifyService.showSuccess('Submission Successful', 'Submission Received');
                this._stepper!.next();

              break;
          }
        }
        ,(error: any) =>{
          this._processError = true;
          this._spinner.hide();
          for (let i = 0; i < this._uploadItems.length; i++) {
            //this._uploadItems[i].uploadProgress = -1;
            this._uploadItems[i].uploadProgress = 0;
            this._uploadItems[i].processed = true;
            this._uploadItems[i].transferOk = false;
            this._uploadItems[i].message = "Transmission error";
            this._uplading = false;
          }
          var txtErr = JSON.stringify(error);
          if (txtErr.includes("406")) {
            this._notifyService.showError('', `It is no longer possible to upload a submission. If you still have something to submit or believe you are receiving this message in error, please contact us at 604-664-7800 or 1-800 663-2782 (toll-free in British Columbia). Please provide us with the appeal number when you call.`);
            //setTimeout(() => { this.finish();}, 2000);
            this.finish();
          }
          
          if (txtErr.includes("422")) {
            this._notifyService.showError('Uploading error', `We apologize, one or more of your documents may be encrypted or contain Microsoft Word macros. Please edit your submission and try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or email us at appeals@wcat.bc.ca for assistance.`);
            this._stepper?.previous();
          }
          else{
            this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
            //setTimeout(() => { this._stepper?.previous();}, 2000);
            this._stepper?.previous();
          }
        },);
       }

       if(this._navData?.submissionType == 'SUBMGNRC'){
      this._submSrvc.genericSubmission(this._navData?.appealCaseID!, this._navData.appealNo!, filesForm).subscribe(event => {
        switch(event.type){
          case HttpEventType.Sent:
            //request made
            break;
          case HttpEventType.ResponseHeader:
            //response header received
            break;
            case HttpEventType.UploadProgress:
              //upload progress
              for (let i = 0; i < this._uploadItems.length; i++) {
              this._uploadItems[i].uploadProgress = Math.round(100 * event.loaded / event.total!);
              }
            break;
            case HttpEventType.Response:
              //response received
              for (let i = 0; i < this._uploadItems.length; i++) {
                this._uploadItems[i].message = "File Uploaded Successfully";
                this._uploadItems[i].processed = true;
                this._uploadItems[i].transferOk = true;
              }
              this._spinner.hide();
              this._notifyService.showSuccess('Submission Successful', 'Submission Received');
              this._stepper!.next();

            break;
        }
      }
      ,(error: any) =>{
        this._processError = true;
        this._spinner.hide();
        for (let i = 0; i < this._uploadItems.length; i++) {
          //this._uploadItems[i].uploadProgress = -1;
          this._uploadItems[i].uploadProgress = 0;
          this._uploadItems[i].processed = true;
          this._uploadItems[i].transferOk = false;
          this._uploadItems[i].message = "Transmission error";
          this._uplading = false;
        }
        var txtErr = JSON.stringify(error);
        if (txtErr.includes("406")) {
          this._notifyService.showError('', `It is no longer possible to upload a submission. If you still have something to submit or believe you are receiving this message in error, please contact us at 604-664-7800 or 1-800 663-2782 (toll-free in British Columbia). Please provide us with the appeal number when you call.`);
          //setTimeout(() => { this.finish();}, 2000);
          this.finish();
        }
        if (txtErr.includes("422")) {
          this._notifyService.showError('Uploading error', `We apologize, one or more of your documents may be encrypted or contain Microsoft Word macros. Please edit your submission and try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or email us at appeals@wcat.bc.ca for assistance.`);
          this._stepper?.previous();
        }
        else{
          this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
          //setTimeout(() => { this._stepper?.previous();}, 2000);
          this._stepper?.previous();
        }
      });
       }
    }
  }




  printACopy(){
    window.print();
  }

  finish(){
    //var landing = this._globalSupport.popUpToLandingEntry();
    //this._router.navigate([landing?.rootPath]);
    this._wcatParameterService.submissionType = '';
    var lstEntry = this._globalSupport.popLastVisitedEntry();
    this._router.navigate([lstEntry!.routePath!]);

  }

  appealDetNewTab(){
    this._globalSupport.removeNavEntryVisited("appeal-sub");
    this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'APPLDET',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber, '','',Number(this._userData!.claimBase!.wCATOrgRoleId));
    const url = this._router.serializeUrl(
      this._router.createUrlTree(['appeal-det'])
    );
    window.open(url, '_blank');
  }


  showFile(item: UploadFileData){
    this.dialog.open(ShowfileDialogComponent, { data: item,
      panelClass: 'full-width-dialog', width: '100%', disableClose: true });
  }

  navMakeSub(appealNo: string, origin: string){
    this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,'', '',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-sub']);
  }

  navMakeNoSub(appealNo: string, origin: string){
    this._wcatParameterService.submissionType = this._navData?.submissionType;
    this._globalSupport.setNavData(this._navData?.appealNo, this._navData?.origin, 'SUBMSS',this._navData?.origDocumentType, this._navData?.respDocumentType, this._navData?.wcatCasePartRoleID, this._navData?.appealCaseID, this._navData?.claimNumber,'', '',this._navData?.wcatEmpRoleID);
    this._router.navigate(['appeal-not']);
  }



}






