import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private _toastr: ToastrService) { }

  showSuccess(title: string, message: string){
    this._toastr.success(message,title, { positionClass:'toast-bottom-right', enableHtml: true, closeButton: true });
}

showError(title: string, message: string){
    this._toastr.error(message,title, { positionClass:'toast-bottom-right', disableTimeOut: true,enableHtml: true, closeButton: true, tapToDismiss: false });
}

showInfo(message: string, title: string){
    this._toastr.info(message,title, { positionClass:'toast-top-right', enableHtml: true, disableTimeOut: true, tapToDismiss: false, closeButton: true });
}

showInfoShort(message: string, title: string){
  this._toastr.info(message,title, { positionClass:'toast-top-right', enableHtml: true , closeButton: true,disableTimeOut: true, tapToDismiss: false });
}

showWarning(message: string, title: string){
    this._toastr.warning(message, title, { positionClass:'toast-top-right', enableHtml: true, disableTimeOut: true, tapToDismiss: false, closeButton: true });
}

showInputWarning(title: string, message: string){
  this._toastr.warning(message,title, { positionClass:'toast-bottom-right', enableHtml: true, disableTimeOut: true, tapToDismiss: false, closeButton: true});
}
}
