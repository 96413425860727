import { Injectable } from '@angular/core';
import { Dictionary } from '../models/Dictionary';
import { IDictionary } from '../models/IDictionary';

@Injectable({
  providedIn: 'root'
})
export class GlobalDataService {
  noSubmissionInst: IDictionary<string>;
  constructor() {
    this.noSubmissionInst = new Dictionary<string>();
    this.__populateDictionaries();
  }

  __populateDictionaries(){

    //No Submission Instructions for Outgoing Document
    this.noSubmissionInst.Add('WCD004',"If you do not plan to respond, please let WCAT know you have nothing to submit. We will proceed with your appeal based on the information we have received." + '\n' + "Other parties participating on the appeal will also have an opportunity to respond.");
    this.noSubmissionInst.Add('WCD026',"If you do not plan to respond, please let WCAT know you have nothing to submit. Submissions will be complete and we will decide the appeal based on the information we have.");
    this.noSubmissionInst.Add('WCD028',"If you do not plan to respond, please let WCAT know you have nothing to submit. The appeal will proceed based on materials received from the appellant and information in the WorkSafeBC file.");
    this.noSubmissionInst.Add('WCD034',"If you do not plan to respond, please let WCAT know you have nothing to submit. We will proceed based on the information we have on file. If another party is participating they will have the opportunity to send in a submission.");
    this.noSubmissionInst.Add('WCD035',"If you do not plan to respond, please let WCAT know you have nothing to submit. Submissions will be complete and we will decide the appeal based on the information we have.");
    this.noSubmissionInst.Add('WCD036',"If you do not plan to respond, please let WCAT know you have nothing to submit. The appeal will proceed based on materials received from the appellant and information in the WorkSafeBC file.");
    this.noSubmissionInst.Add('WCD085',"If you do not plan to respond, please let WCAT know you have nothing to submit. We will proceed based on the information we have on file. If another party is participating they will have the opportunity to send in a submission.");
    this.noSubmissionInst.Add('WCD100',"If you do not plan to respond, please let WCAT know you have nothing to submit. Submissions will be complete and we will decide the appeal based on the information we have.");
    this.noSubmissionInst.Add('WCD101',"If you do not plan to respond, please let WCAT know you have nothing to submit. The appeal will proceed based on materials received from the appellant and information in the WorkSafeBC file.");
    this.noSubmissionInst.Add('WCD211',"If you advise us that you will not be requesting a reactivation, we will assume you do not want to continue with the appeal. We will close the appeal and take no further action. You will not be able to reactivate your appeal unless you later apply for additional time to do so.");
    this.noSubmissionInst.Add('WCD212',"If you advise us that you will not be requesting a reactivation, we will assume you do not want to continue with the appeal. We will close the appeal and take no further action. You will not be able to reactivate your appeal unless you later apply for additional time to do so.");
    this.noSubmissionInst.Add('WCD218',"If you do not plan to respond, please let WCAT know you have nothing to submit. We will proceed based on the information we have on file. If another party is participating they will have the opportunity to send in a submission.");
    this.noSubmissionInst.Add('WCD219',"If you do not plan to respond, please let WCAT know you have nothing to submit. Submissions will be complete and we will decide whether your appeal is late based on the information we have.");    this.noSubmissionInst.Add('WCD220',"If you do not plan to respond, please let WCAT know you have nothing to submit. The appeal will proceed based on materials received from the appellant and information in the WorkSafeBC file.");
    this.noSubmissionInst.Add('WCD220',"If you do not plan to respond, please let WCAT know you have nothing to submit. A decision about whether the appellant can start a late appeal will be made based on materials received from the appellant and information in the WorkSafeBC file.");    this.noSubmissionInst.Add('WCD290',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD291',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD293',"If you do not plan to respond, please let WCAT know you have nothing to submit. Because we are asking for clarification, we may still need further information to proceed with the appeal.");
    this.noSubmissionInst.Add('WCD649',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD717',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD728',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD729',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD730',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD731',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD733',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD734',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD735',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD736',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");
    this.noSubmissionInst.Add('WCD737',"If you do not plan to respond, please let WCAT know you have nothing to submit. Please note that this may mean that WCAT will dismiss your appeal.");

    
  }

}
