import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSupport } from './services/shared/global-support';
import { AuthEmpRoleId } from './services/models/IIntAuthEmpRoleId';
import { ClaimBase } from './services/models/IClaimBase';
import { ProfileData } from './services/models/IProfileData';
import { Profile001Service } from './services/profile/profile001.service';
import { AuthService } from './services/shared/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { AppInitService } from './services/config/app-init.service';
import { AppInsightService } from './services/shared/app-insight.service';
import { protectedResources } from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'WCAT Online Services';
   @HostListener('window:beforeunload', ['$event'])
   public beforeunloadHandler($event: any) {
      // var tms = sessionStorage.getItem('__LFT');
      // var times  = ((tms == undefined ||  tms == null) ? 0 : parseInt(tms)) + 1;
			// sessionStorage.setItem('__LFT', times.toString());
     return undefined;
  }




  constructor(private _router: Router,
            private _activatedRoute: ActivatedRoute,
            private _profsvc: Profile001Service,
            private _globalSupport : GlobalSupport,
            private _authSrv: AuthService,
            private _bnIdle: BnNgIdleService,
            private _inicfg: AppInitService,
            private _loggingService: AppInsightService){

              var sessionTimeOut = 1800; //this._inicfg.config?.SESSION_MAX_MINS!*60;
              this._bnIdle.startWatching(sessionTimeOut).subscribe((res) => {
                if(res) {
                    this._authSrv.logoutFlow();
                }
              })

  }


  ngOnInit(): void { 
    this._loggingService._appInsights.trackTrace({message: 'ProtectedResources WCATAppeals EndPoint: ' + protectedResources.WCATAppeals.endpoint});
    this._loggingService._appInsights.trackTrace({message: 'ProtectedResources WCATAppeals Scopes: ' + protectedResources.WCATAppeals.scopes});
    this._loggingService._appInsights.trackTrace({message: 'ProtectedResources WCATApim EndPoint: ' + protectedResources.WCATApim.endpoint});
    this._loggingService._appInsights.trackTrace({message: 'ProtectedResources WCATApim Scopes: ' + protectedResources.WCATApim.scopes}); 
  }


}

