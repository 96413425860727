<p></p>
<div class="" id="">
  <div class="alert alert-warning" role="alert" *ngIf="origin!.indexOf('PST') != -1" >
    <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
      <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"/>
      <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"/>
    </svg></span><span style="padding-left: 10px;">Appeals completed before March 27, 2023 are not visible on WCAT Online Services.</span>
  </div>
  <app-appeal-shrt [appealData]="paggedappeals" [origin]="origin" (sortRequest)="sortRequestHandler($event)"></app-appeal-shrt>
</div>
<mat-paginator
    (page)="handlePageEvent($event)"
    [length]="appealheaderData?.appealStateCount"
    [pageSize]="pageSize"
    [showFirstLastButtons]="showFirstLastButtons"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    [hidePageSize]="false"
    aria-label="Select page">
</mat-paginator>

