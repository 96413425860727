<app-sub-header></app-sub-header>

<div class="apl-det">
  <div class="" id="">
    <h1 class="display-6" id="usr-fullname">{{ this._userData?.userFirstName }} {{ this._userData?.userLastName }}</h1>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL2'">{{ this._userData?.employerName }}</h2>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL13' && this._userData?.clientName" >{{ this._userData?.clientName }}</h2>
    <h2 class="display-6" id="usr-fullname" *ngIf="this._userData?.claimBase?.wCATRoleType == 'RL13' && !this._userData?.clientName" >{{ this._userData?.employerName }}</h2>
    <h4 class="sub-dis"><span id="usr-id"></span></h4>
  </div>
  <hr class="style1">
  <div class="d-flex flex-wrap pt-3">
    <div class="" id="lft-cnt">
    <h3><span id="" >Confirm that you have nothing to submit</span></h3>

    <!-- <p claSS="pt-2">{{ _instructions }}</p> -->
    <div style="white-space: pre-line" claSS="pt-2">{{_instructions}} </div>
    <p claSS="pt-2"> </p>
    <p claSS="fw-bold pt-2">You are confirming that you have nothing to submit for appeal number {{this._appealNo}}.</p>

  </div>
</div>
<div class="d-flex flex-wrap btn-cntr pt-5">
  <div class="" id="">
  <input class="sqr-btn" type="button" (click)="confirmNothing()" value="Submit"/>
  </div>
  <div class="" id="cnf-lnk">
     <span style="vertical-align: middle; line-height: 48pt;"> <a class="nav-link initial lnk-grn" aria-current="page" href="javascript:void(0);" (click)="cancelNothing()">Cancel</a></span>
  </div>
</div>
