<nav class="navbar navbar-expand-lg navbar-dark">
  <div class="container-fluid">

    <a class="mnlogo" href="#"><img src="../assets/shared/images/wcat-logo.png"  class="img-fluid" alt="" /></a>
    <span class="hdr-title float-left">Online Services</span>

    <div class="nowrap navbar-collapse justify-content-end" id="navbarNav">

      <mat-icon-button mat-icon-button [matMenuTriggerFor]="menu" (click)="menuOpened()" class="navbar-toggler-icon">
      </mat-icon-button>
      <mat-menu #menu="matMenu">

        <div *ngIf="this._roleType=='RL2'">
          <button  mat-menu-item (click)="addOrg()">
            <span>Add employer</span>
          </button>
        </div>
        <div *ngIf="this._roleType=='RL13'">
          <button  mat-menu-item (click)="AddRepFirm()">
            <span>Add representative firm</span>
          </button>
        </div>

        <button mat-menu-item (click)="updEmail()">
          <span>Change email</span>
        </button>

        <button mat-menu-item (click)="updPass()" >
          <span>Change password</span>
        </button>




          <mat-divider></mat-divider>
          <button mat-menu-item (click)="gotoHelp()">
            <span>Help</span>
          </button>
          <button mat-menu-item (click)="contactUs()">
            <span>Contact us</span>
          </button>

        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logOut()">
          <span>Sign out</span>
        </button>
      </mat-menu>

    </div>
  </div>
</nav>

