<div class="d-flex flex-wrap">
  <div>
    <h4></h4>
    <span></span>
  </div>
  <div class="sub-hdr-lft pt-4">
    <h4>Appeal details</h4>
    <span></span>
  </div>
</div>
<div class="tab-cnt">
  <div id="" class="main-cnt rnd-01 m-3">
    <div class="d-flex flex-wrap p-2">
      <div class="lft-col" id="">
        <div class="spt-1">
          <h5><span class="hdr-01">APPEAL OR APPLICATION TYPE</span></h5>
          <span class="appl-itm ">{{ _appealTypeDesc }}</span>
        </div>
        <div class="spt-1" *ngIf="_isActive">
          <h5><span class="hdr-01">CASE OWNER</span></h5>
          <span class="appl-itm">{{_appealDet?.caseOwner}}</span>
        </div>
        <div class="spt-1" *ngIf="_origin?.includes('EMP') && _appealDet?.appealType == 'CMP'">
          <h5><span class="hdr-01">WORKER</span></h5>
          <span class="appl-itm">{{ _appealDet?.workerFirstName }} {{ _appealDet?.workerLastName }}</span>
        </div>
        <div class="spt-1" *ngIf="_origin?.includes('REP')">
          <h5><span class="hdr-01">CLIENT</span></h5>
          <span class="appl-itm">{{ _clientName }}</span>
        </div>
      </div>
      <div class="mid-col" id="">
        <div class="spt-1" *ngIf="(!_appealDet?.appealType?.toUpperCase()?.includes('OHS') && !_appealDet?.appealType?.toUpperCase()?.includes('ASM'))">
          <h5><span class="hdr-01">CLAIM NUMBER</span></h5>
          <span class="appl-itm" >{{_appealDet?.claimNumber}}</span>
        </div>

        <div class="spt-1" *ngIf="_appealDet?.appealType?.toUpperCase()?.includes('OHS')">
          <h5><span class="hdr-01">PENALTY REPORT NUMBER</span></h5>
          <span class="appl-itm" >{{_appealDet?.relatedRefNumber}}</span>
        </div>

        <div class="spt-1" *ngIf="_appealDet?.appealType?.toUpperCase()?.includes('ASM')">
          <h5><span class="hdr-01">EMPLOYER ACCOUNT NUMBER</span></h5>
          <span class="appl-itm" >{{_appealDet?.employers![0].ean}}</span>
        </div>

        <div class="spt-1">
          <h5><span class="hdr-01">DECISION DATE UNDER APPEAL</span></h5>
          <span class="appl-itm">{{_appealDet?.reviewDecisionDate | date: "mediumDate"}}</span>
      </div>
      </div>
      <div class="rgt-col" id="">
        <div class="spt-1">
        <h5><span class="hdr-01">REVIEW DIVISION NUMBER</span></h5>
        <span class="appl-itm">{{_appealDet?.reviewNumber}}</span>
      </div>
      <div class="spt-1"  *ngIf="_origin != 'EMP'">
        <h5><span class="hdr-01"><!--REVIEW DIVISION DECISION DATE--></span></h5>
        <span class="appl-itm"><!--{{_appealDet?.reviewDecisionDate | date: "mediumDate"}}--></span>
      </div>

      </div>
    </div>
  </div>
</div>
