<app-sub-header></app-sub-header>

<div class="apl-det">
  <div class="" id="">
    <h1 class="display-6" id="usr-fullname">{{ this._userData?.userFirstName! | capitalCase }} {{ this._userData?.userLastName! | capitalCase}}</h1>
    <h2 class="display-6" id="usr-fullname">{{ this._userData?.employerName }}</h2>
    <h4 class="sub-dis"><span id="usr-id"></span></h4>
  </div>


<mat-stepper linear="false" #stepper>
  <mat-step [stepControl]="_fstFrmGrp" [editable]="_isEditable" class="cntrls">


    <form [formGroup]="_fstFrmGrp">
      <ng-template matStepLabel>Fill</ng-template>

      <!--FRM 1-->
      <div class="d-flex flex-wrap pt-3">
          <div class="" id="lft-cnt">
            <h3><span id="" >Appeal number: {{_appealNo}}</span></h3>

          <!--TOP CONTENT-->

          <!--["SUBMGNRC",]-->
          <ng-template #CNT_1_T [ngIf]="this._navData!.submissionType == 'SUBMGNRC'">
            <p class="fw-bold pt-2"></p>
            <span>WCAT will contact you when it needs your submission. Use this page if you need to contact us for other reasons, for example:.</span><br/>
            <span></span>
              <ul>
                <li>To request a withdrawal</li>
                <li>To ask for additional time to provide a submission</li>
                <li>To provide evidence, like a medical report, before your hearing</li>
              </ul>
            <br/>
            <br/>
            <br/>
            <p class="fw-bold pt-2">Contact WCAT</p>
            <span>You can upload documents and/or use the textbox below if you need to contact WCAT.<br/>If submission deadlines have passed, WCAT will not generally accept late or unsolicited submissions.</span><br/>
          </ng-template>

          <!--["WCD294",]-->
            <ng-template #CNT_1_T [ngIf]="this._navData!.respDocumentType == 'WCD294'">
              <p class="fw-bold pt-2"></p>
              <span>Please carefully review the letter from WCAT requesting a clarification.<br/> The letter is in the <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <span> If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you are not providing one.</span><br/><br/>
              <p class="fw-bold pt-2">Submit a clarification</p>
              <span>You can upload documents and/or use the textbox below to provide your clarification.</span><br/>
            </ng-template>


            <!--["WCD292","WCD032","WCD033"]-->
            <ng-template #CNT_2_T [ngIf]="this._navData!.respDocumentType == 'WCD292' || this._navData!.respDocumentType == 'WCD032' || this._navData!.respDocumentType == 'WCD033'">
              <p class="fw-bold pt-2"></p>
              <span>Please carefully review the letter from WCAT requesting your submission about whether this appeal should be dismissed.<br/> The letter is in the <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <p class="fw-bold pt-2"></p>
              <span>Learn more about this step on our website:</span>
                <ul>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/prepare-your-case/" target="_blank">Prepare Your Case<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/present-evidence-arguments/" target="_blank">Present Evidence and Arguments<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                </ul>
              <p class="fw-bold pt-2"></p>
              <span>If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you are not providing one.</span><br/><br/>
              <p class="fw-bold pt-2">Make a submission</p><br/>
              <span>You can upload documents and/or use the textbox below to respond.</span><br/>
            </ng-template>


            <!--["WCD295","WCD024","WCD027","WCD296"]-->
            <ng-template #CNT_3_T [ngIf]="this._navData!.respDocumentType == 'WCD295' || this._navData!.respDocumentType == 'WCD024' || this._navData!.respDocumentType == 'WCD027' || this._navData!.respDocumentType == 'WCD296'">
              <p class="fw-bold pt-1"></p>
              <span>Please carefully review the letter from WCAT requesting your submission.<br/> The letter is in the <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <p class="fw-bold pt-2"></p>
              <span>Learn more about this step on our website:</span>
                <ul>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/prepare-your-case/" target="_blank">Prepare Your Case<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/present-evidence-arguments/" target="_blank">Present Evidence and Arguments<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                </ul>
              <p class="fw-bold pt-2"></p>
              <span>If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you are not providing one.</span><br/><br/>
              <p class="fw-bold pt-2">Make a submission</p>
              <span>You can upload documents and/or use the textbox below to present your case.</span>
            </ng-template>


            <!--["WCD198",]-->
            <ng-template #CNT_4_T [ngIf]="this._navData!.respDocumentType == 'WCD198'">
              <p class="fw-bold pt-2"></p>
              <span>Please carefully review the letter from WCAT requesting your submission about whether you should be granted additional time to appeal. <br/>The letter is in the <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <p class="fw-bold pt-2"></p>
              <span>Learn more about this step on our website:</span>
                <ul>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/prepare-your-case/" target="_blank">Prepare Your Case<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/present-evidence-arguments/" target="_blank">Present Evidence and Arguments<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                </ul>
              <p class="fw-bold pt-2"></p>
              <span>If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you are not providing one.</span><br/><br/>
              <p class="fw-bold pt-2">Make a submission</p><br/>
              <span>You can upload documents and/or use the textbox below to explain why your appeal is late and why WCAT should grant you an extension of time to appeal.</span><br/>
            </ng-template>

            <!--["WCD200",]-->
            <ng-template #CNT_5_T [ngIf]="this._navData!.respDocumentType == 'WCD200'">
              <p class="fw-bold pt-2"></p>
              <span>Please carefully review the letter from WCAT requesting your submission about whether the appellant should be granted additional time to appeal.<br/> The letter is in the <a class="lnk-grn" aria-current="page"href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <p class="fw-bold pt-2"></p>
              <span>Learn more about this step on our website:</span>
                <ul>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/prepare-your-case/" target="_blank">Prepare Your Case<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                  <li><a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/appeal-a-decision/present-evidence-arguments/" target="_blank">Present Evidence and Arguments<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></li>
                </ul>
              <p class="fw-bold pt-2"></p>
              <span>If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you are not providing one.</span><br/><br/>
              <p class="fw-bold pt-2">Make a submission</p><br/>
              <span>You can upload documents and/or use the textbox below to explain if WCAT should allow the appellant more time to appeal.</span><br/>
            </ng-template>

            <!--["WCD199",]-->
            <ng-template #CNT_6_T [ngIf]="this._navData!.respDocumentType == 'WCD199'">
              <p class="fw-bold pt-2"></p>
              <span>Please carefully review the letter from WCAT requesting your rebuttal submission about whether the appellant should have additional time to appeal.<br/> The letter is in the <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <p class="fw-bold pt-2"></p>
              <span>If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you are not providing one.</span><br/><br/>
              <p class="fw-bold pt-2">Make a submission</p><br/>
              <span>You can upload documents and/or use the textbox below to address the respondent’s submission about whether you should be granted more time to appeal.</span><br/>
            </ng-template>

            <!--["WCD025","WCD031","WCD297"]-->
            <ng-template #CNT_7_T [ngIf]="this._navData!.respDocumentType == 'WCD025' || this._navData!.respDocumentType == 'WCD031' || this._navData!.respDocumentType == 'WCD297'">
              <p class="fw-bold pt-2"></p>
              <span>Please carefully review the letter from WCAT requesting your final (rebuttal) submission.<br/> The letter is in the <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <p class="fw-bold pt-2"></p>
              <span>If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you are not providing one.</span><br/><br/>
              <p class="fw-bold pt-2">Make a final submission</p><br/>
              <span>You can upload documents and/or use the textbox below to address the respondent’s submission.</span><br/>
            </ng-template>

            <!--["WCD213","WCD213"]-->
            <ng-template #CNT_8_T [ngIf]="this._navData!.respDocumentType == 'WCD213'">
              <p class="fw-bold pt-2"></p>
              <span>Please carefully review the letter from WCAT about your suspended appeal. <br/>The letter is in the <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="appealDetNewTab()">document list<img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</span><br/>
              <p class="fw-bold pt-2"></p>
              <span>If you do not plan to respond, <a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navMakeNoSub(this._appealNo!,  _origin!)">let WCAT know that you have nothing to submit</a>.</span><br/>
              <span>If your response is not received by the deadline, we will assume you do not want to reactivate your appeal, and the appeal will be closed.</span><br/><br/>
              <p class="fw-bold pt-2">Reactivate your appeal</p><br/>
              <span>You can upload documents and/or use the textbox below to respond.</span><br/>
            </ng-template>

          <!--TOP CONTENT-->
          </div>
          <div class="" id="rgt-lnk">
            <!-- <h4>Find out how to...</h4>
            <span>
            <p></p>
            <p><a class="lnk-grn" aria-current="page" href="#">Prepare your case <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></p>
            <p><a class="lnk-grn" aria-current="page" href="#">Present evidence and arguments <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a></p>
            </span> -->
          </div>
    </div>




      <!--MEDIUM CONTENT-->

      <!--["SUBMGNRC",]-->
      <ng-template #CNT_1_M [ngIf]="this._navData!.submissionType == 'SUBMGNRC'"></ng-template>

      <!--["WCD294",]-->
      <ng-template #CNT_1_M [ngIf]="this._navData!.respDocumentType == 'WCD294'"></ng-template>

      <!--["WCD292","WCD032","WCD033"]-->
      <ng-template #CNT_2_M [ngIf]="this._navData!.respDocumentType == 'WCD292' || this._navData!.respDocumentType == 'WCD032' || this._navData!.respDocumentType == 'WCD033'"></ng-template>

      <!--["WCD295","WCD024","WCD027","WCD296"]-->
      <ng-template #CNT_3_M [ngIf]="this._navData!.respDocumentType == 'WCD295' || this._navData!.respDocumentType == 'WCD024' || this._navData!.respDocumentType == 'WCD027' || this._navData!.respDocumentType == 'WCD296'"></ng-template>

      <!--["WCD198",]-->
      <ng-template #CNT_4_M [ngIf]="this._navData!.respDocumentType == 'WCD198'"></ng-template>

      <!--["WCD200",]-->
      <ng-template #CNT_5_M [ngIf]="this._navData!.respDocumentType == 'WCD200'"></ng-template>

      <!--["WCD199",]-->
      <ng-template #CNT_6_M [ngIf]="this._navData!.respDocumentType == 'WCD199'"></ng-template>

      <!--["WCD025","WCD031","WCD297"]-->
      <ng-template #CNT_7_M [ngIf]="this._navData!.respDocumentType == 'WCD025' || this._navData!.respDocumentType == 'WCD031' || this._navData!.respDocumentType == 'WCD297'"></ng-template>

      <!--["WCD213"]-->
      <ng-template #CNT_8_M [ngIf]="this._navData!.respDocumentType == 'WCD213'"></ng-template>

      <!--MEDIUM CONTENT-->
    <textarea autofocus class="form-control rnd-01" id="summ-01" rows="5" placeholder="Enter up to 2,500 characters." maxlength="2500" formControlName="subDetails01" ></textarea>

    <!--BOTTOM CONTENT-->

    <!--["SUBMGNRC",]-->
    <ng-template #CNT_1_B [ngIf]="this._navData!.submissionType == 'SUBMGNRC'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1"></span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>


    <!--["WCD294",]-->
    <ng-template #CNT_1_B [ngIf]="this._navData!.respDocumentType == 'WCD294'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our clarification request.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>


    <!--["WCD292","WCD032","WCD033"]-->
    <ng-template #CNT_2_B [ngIf]="this._navData!.respDocumentType == 'WCD292' || this._navData!.respDocumentType == 'WCD032' || this._navData!.respDocumentType == 'WCD033'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our submission request.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>

    <!--["WCD295","WCD024","WCD027","WCD296"]-->
    <ng-template #CNT_3_B [ngIf]="this._navData!.respDocumentType == 'WCD295' || this._navData!.respDocumentType == 'WCD024' || this._navData!.respDocumentType == 'WCD027' || this._navData!.respDocumentType == 'WCD296'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our submission request.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>

    <!--["WCD198",]-->
    <ng-template #CNT_4_B [ngIf]="this._navData!.respDocumentType == 'WCD198'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our submission request.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>

    <!--["WCD200",]-->
    <ng-template #CNT_5_B [ngIf]="this._navData!.respDocumentType == 'WCD200'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our submission request.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>


    <!--["WCD199",]-->
    <ng-template #CNT_6_B [ngIf]="this._navData!.respDocumentType == 'WCD199'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our submission request.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>


    <!--["WCD025","WCD031","WCD297"]-->
    <ng-template #CNT_7_B [ngIf]="this._navData!.respDocumentType == 'WCD025' || this._navData!.respDocumentType == 'WCD031' || this._navData!.respDocumentType == 'WCD297'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our submission request. Please do not provide additional evidence on rebuttal.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>

    <!--["WCD213"]-->
    <ng-template #CNT_8_B [ngIf]="this._navData!.respDocumentType == 'WCD213'">
      <h4 class="pt-5">Upload documents and files</h4>
      <span class="pt-1">Provide a response to our submission request.</span>
        <ul>
          <li>You do not need to send information that is already in the WorkSafeBC file.</li>
          <li>Do not include links to websites. WCAT will not consider linked information.</li>
          <li>Files can be up to 10MB.</li>
          <li>There is limit of 10 files.</li>
          <li>Upload only these file types: DOC, PDF, JPG, JPEG or RTF. Other file types can be submitted <a class="lnk-grn" aria-current="page" href="https://www.wcat.bc.ca/home/contact-wcat/" target="_blank">by mail or in person <img src="../assets/shared/images/navigate-icon.svg" alt="navigate"></a>.</li>
        </ul>
    </ng-template>

    <!--BOTTOM CONTENT-->
    <div class="rnd-01 rnd-02" id="upld-cnt" style="background: url(assets/shared/images/upld-icon.svg)  no-repeat center right">
      <p id="instrUpld"><b>Drag and drop files.</b> You can also <a class="lnk-grn" aria-current="page" (click)="selectFiles()">choose a file</a><span class=""> or </span><a class="lnk-grn " aria-current="page" (click)="selectFiles()">take a photo.</a></p>
      <p class="upld-tot">Total to upload: {{this._totalSize | number:'1.2-2'}}MB</p>
      <div class="upld-itms">
        <div class="sel-itms">
          <app-dragndroplist [uploadItems]="_uploadItems" (uploadItemsOut)="receiveOutput($event)" (showFileOut)="showFile($event)" [src]="'1STP'"></app-dragndroplist>
        </div>
          <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" accept=".docx,.pdf,.jpg,.jpeg,.rtf">
              <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector"></ng-template>
          </ngx-file-drop>
      </div>
    </div>
    <div class="d-flex btn-cntr">
      <div class="" id="">
        <input class="sqr-btn" type="button" value="Submit" [disabled]="!_uploadItems"  (click)="onNext(stepper,0)"/>  <!-- (click)="uploadFiles()-->
      </div>
      <div class="cnf-lnk">
         <span style="vertical-align: middle; line-height: 48pt;"> <a class="lnk-grn  p-4" aria-current="page" (click)="cancelSubmission()">Cancel</a></span>
      </div>
    </div>

      <!--FRM 1-->
    </form>
  </mat-step>



  <mat-step [stepControl]="_scdFrmGrp" [editable]="_isEditable">
    <form [formGroup]="_scdFrmGrp">
      <ng-template matStepLabel>Confirm</ng-template>

      <!--FRM 2-->

      <div class="d-flex flex-wrap pt-3">
        <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#fff"
        type="ball-scale-multiple"
        [fullScreen] = "false"><p style="color: white; margin-top: 60px;" > Uploading... </p></ngx-spinner>
        <div class="cnt-frm2">
          <h3><span id="" >Would you like to continue?</span></h3>
          <p class="fw-bold pt-2">Review and submit your documents</p>
          <span>
              <ul>
                <li>All information you submit will be kept by WCAT and shared with other parties involved in the appeal.</li>
                <li>Only submit new information or evidence that isn't already on the WorkSafeBC file.</li>
                <li [ngIf]="this._navData!.submissionType != 'SUBMGNRC'">You can only submit evidence and arguments once. Once you have clicked Submit, you will not be able to provide more documents through this link.</li>
                <li>Keep a copy of the files you submit.</li>
              </ul>
          </span>
            <p class="fw-bold pt-2">You are submitting the following for appeal number {{_appealNo}}.</p>
            <textarea class="form-control rnd-01" id="summ-02" rows="6" placeholder="Enter up to 2,500 characters." formControlName="subDetails02"  [readOnly]="true"></textarea>
            <div class="upld-itms2">
              <div class="sel-itms itms-2">
                <app-dragndroplist [uploadItems]="_uploadItems" (uploadItemsOut) = "receiveOutput($event)"  class="disable-cls" [src]="'2STP'"></app-dragndroplist>
              </div>
            </div>
        </div>
      </div>
      <div class="d-flex btn-cntr" *ngIf="!_uplading">
        <div class="cnf-lnk" id="">
        <input class="sqr-btn" type="button" value="Submit" [disabled]="!_uploadItems"  (click)="uploadFiles()"/>
        </div>
        <div>
          <span> <a class="lnk-grn edtBtn" aria-current="page" matStepperPrevious (click)="editSubmission(stepper,1)">Edit your submission</a></span>
       </div>
        <div >
          <span style="vertical-align: middle; line-height: 48pt;"> <a class="lnk-grn  p-4" aria-current="page" (click)="cancelSubmission()">Cancel</a></span>
        </div>
      </div>


      <!--FRM 2-->

    </form>
  </mat-step>



  <mat-step>
    <ng-template matStepLabel>Done</ng-template>

     <!--FRM 3-->

     <div class="d-flex flex-wrap pt-3">
      <div class="" id="lft-cnt">
        <div class="" id="">
            <h3><span id="" >Thank you for your submission</span></h3>
            <p class="pt-2">Your files have been sent to WCAT. Once WCAT has reviewed your submission, they will be available to everyone participating on the appeal. We will send you a letter outlining any next steps.</p>
            <p class="fw-bold pt-2">Date of submission: {{_submittedDate | date:'medium'}}</p>
            <p class="fw-bold pt-2">Appeal number: {{_appealNo}}</p>
            <p class="pt-2">Summary of submission:</p>
            <p class="p-3"><b>{{_textToFile}}</b></p>
            <div>
              <div *ngFor="let fl of _uploadItems">
                <div class="rowItm">{{fl.file!.name}}</div>
                  <span [ngClass]="fl.uploadProgress === -1 ? 'errmsg':'succss'">{{fl.message}}</span>
                </div>
              </div>
        </div>

        <div class="d-flex btn-cntr pt-5 cntrls">
          <div class="" id="">
            <input class="sqr-btn " type="button" (click)="printACopy()" value="Print a Copy"/>
          </div>
          <div class="cnf-lnk" id="">
            <span style="vertical-align: middle; line-height: 48pt;"> <a class="lnk-grn  p-4" aria-current="page" (click)="finish()">Finish</a></span>
          </div>
        </div>
    </div>
  </div>

     <!--FRM 3-->

  </mat-step>
</mat-stepper>


</div>



