import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export const environment = {
  production: false,
  name: 'development',
  appInsights: {
    instrumentationKey: '1767b0ad-e7de-4544-a613-705a3ef8f7aa'
  }
};

  export const b2cPolicies = {
                    names: {
                      signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
                      editProfile: "B2C_1A_PROFILEEDIT",
                      resetPassword: "B2C_1A_PASSWORDRESET",
                      addRole: "B2C_1A_ADDROLE",
                      addOrg: "B2C_1A_ADD_ADDITIONAL_ORG",
                      updateEmail: "B2C_1A_UPDATE_EMAIL"
                    },
                    authorities: {
                        signUpSignIn: {
                          authority: "https://dv-login.wcat.bc.ca/9c584e15-e2b2-4c83-a1c0-b08c8b144f5f/B2C_1A_SIGNUP_SIGNIN",
                        },
                        editProfile: {
                          authority: "https://dv-login.wcat.bc.ca/9c584e15-e2b2-4c83-a1c0-b08c8b144f5f/B2C_1A_PROFILEEDIT"
                        },
                        resetPassword: {
                          authority: "https://dv-login.wcat.bc.ca/9c584e15-e2b2-4c83-a1c0-b08c8b144f5f/B2C_1A_PASSWORDRESET"
                        },
                        addRole: {
                          authority: "https://dv-login.wcat.bc.ca/9c584e15-e2b2-4c83-a1c0-b08c8b144f5f/B2C_1A_ADDROLE"
                        },
                        addOrg: {
                          authority: "https://dv-login.wcat.bc.ca/9c584e15-e2b2-4c83-a1c0-b08c8b144f5f/B2C_1A_ADD_ADDITIONAL_ORG"
                        },
                        updateEmail: {
                          authority: "https://dv-login.wcat.bc.ca/9c584e15-e2b2-4c83-a1c0-b08c8b144f5f/B2C_1A_UPDATE_EMAIL"
                        }
                    },
                    authorityDomain: "dv-login.wcat.bc.ca"
                  };


export const msalConfig: Configuration = {
                  auth: {
                   clientId: 'c98024c2-cd46-4c32-9fb1-c5438faa935d',
                   authority: b2cPolicies.authorities.signUpSignIn.authority,
                   knownAuthorities: [b2cPolicies.authorityDomain],
                   redirectUri: 'https://dv-onlineservices.wcat.bc.ca/auth'
                   //redirectUri: 'https://ln456917.wcbbc.wcbmain.com:5000/auth'
                  },
                  cache: {
                      cacheLocation: BrowserCacheLocation.SessionStorage,
                      storeAuthStateInCookie: isIE,
                      secureCookies: false
                  },
                  system: {
                      loggerOptions: {
                         loggerCallback: (logLevel, message, containsPii) => {
                             //console.log(message);
                          },
                          logLevel: LogLevel.Verbose,
                          piiLoggingEnabled: false
                      }
                  }
              };



export const protectedResources = {
                WCATAppeals: {
                  endpoint: "https://dv-onlineservices.wcat.bc.ca/",
                  scopes: ["https://wcatb2cdev.onmicrosoft.com/wcat-os-api/api.access"],
                },
                WCATApim: {
                  endpoint:"https://dv-api-onlineservices.wcat.bc.ca/",
                  scopes: ["https://wcatb2cdev.onmicrosoft.com/wcat-os-api/api.access"]
                }
              };

//export const loginRequest = {
//                scopes: []
//              };

