import { NgModule,Component  } from '@angular/core';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatDividerModule} from '@angular/material/divider';
import {MatTooltipModule} from '@angular/material/tooltip';


const materialModules = [
  MatExpansionModule,
  MatIconModule,
  DragDropModule,
  MatProgressSpinnerModule,
  MatCardModule,
  MatPaginatorModule,
  MatFormFieldModule,
  MatSelectModule,
  MatButtonToggleModule,
  MatRadioModule,
  MatTabsModule,
  MatTableModule,
  MatSortModule,
  MatCheckboxModule,
  FormsModule,
  ReactiveFormsModule,
  MatProgressBarModule,
  BrowserAnimationsModule,
  MatAutocompleteModule,
  MatInputModule,
  MatListModule,
  MatStepperModule,
  MatDialogModule,
  MatButtonModule,
  MatMenuModule,
  MatDividerModule,
  MatTooltipModule
];

@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    materialModules
  ],
  exports:[
    materialModules
  ]
})
export class MaterialModule { }
