

export interface IIntAuthEmpRoleId{
  wcatEmpRoleID: string;
}

export class AuthEmpRoleId implements IIntAuthEmpRoleId{

  wcatEmpRoleID: string;
  constructor(wcatEmpRoleID: string){
    this.wcatEmpRoleID = wcatEmpRoleID;
  }

}
