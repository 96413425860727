import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { WorkerLandingComponent } from "./components/worker/worker-landing.component";
import { EmployerLandingComponent } from "./components/employer/employer-landing.component";
import { RepresentativeLandingComponent } from "./components/representative/representative-landing.component";
import { AppealUpldComponent } from './components/appeal/appeal-upld.component';
import { PageNotFoundComponent } from './components/shared/navigation/page-not-found.component';
import { AppealDetComponent } from './components/appeal/appeal-det.component';
import { AppealNthgComponent } from "./components/appeal/appeal-nthg.component";
import { AppealNthgcnfmComponent } from "./components/appeal/appeal-nthgcnfm.component";
import { AppComponent } from './app.component';
//import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MsalGuard } from "./services/shared/msal.guard";
import { HomeComponent } from './home.component';
import { AppealSubComponent } from './components/appeal/appeal-sub.component';

/* B2C ROUTES */

const routes: Routes = [
  { path: 'worker-landing', component: WorkerLandingComponent, data: { type:"L" , routeLabel:"Worker Landing" , breadCrumbLabel: "Home", childSequence: 0, key:"WRK", parentKey:"" }, canActivate: [MsalGuard]},
  { path: 'employer-landing', component: EmployerLandingComponent , data: { type:"L" , routeLabel:"Employer Landing" , breadCrumbLabel: "Home", childSequence: 0, key:"EMP", parentKey:"" }, canActivate: [MsalGuard]},
  { path: 'representative-landing', component: RepresentativeLandingComponent , data: { type:"L" , routeLabel:"Representative Landing" , breadCrumbLabel: "Home", childSequence: 0, key:"REP", parentKey:"" }, canActivate: [MsalGuard]},
  { path: 'appeal-srch', component: AppealDetComponent , data: { type:"I" , routeLabel:"Appeal Details" , breadCrumbLabel: "Appeal Details", childSequence: 1, key:"DET", parentKey:"" }, canActivate: [MsalGuard]},
  { path: 'appeal-det', component: AppealDetComponent , data: { type:"C" , routeLabel:"Appeal Details" , breadCrumbLabel: "Appeal Details", childSequence: 1, key:"DET", parentKey:"" }, canActivate: [MsalGuard]},
  { path: 'appeal-subold', component: AppealUpldComponent , data: { type:"C" , routeLabel:"Appeal Submissions" , breadCrumbLabel: "Appeal Submissions", childSequence: 2, key:"SUB", parentKey:"DET"} , canActivate: [MsalGuard]},
  { path: 'appeal-sub', component: AppealSubComponent , data: { type:"C" , routeLabel:"Appeal Submissions" , breadCrumbLabel: "Appeal Submissions", childSequence: 2, key:"SUB", parentKey:"DET"} , canActivate: [MsalGuard]},
  { path: 'appeal-not', component: AppealNthgComponent , data: { type:"C" , routeLabel:"Nothing to Submit" , breadCrumbLabel: "No Submission", childSequence: 3, key:"NTH", parentKey:"DET" }, canActivate: [MsalGuard]},
  { path: 'appeal-notcnfm', component: AppealNthgcnfmComponent , data: { type:"E" , routeLabel:"Sent" , breadCrumbLabel: "Sent", childSequence: 4, key:"NTHCNF", parentKey:"DET" }, canActivate: [MsalGuard]},
  { path: 'auth', component: MsalRedirectComponent },
  { path: 'home', component: MsalRedirectComponent },
  { path: '',pathMatch: 'full', component: HomeComponent}
];



const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', initialNavigation: 'enabledBlocking'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const RoutingComponents = [AppComponent,
                                  WorkerLandingComponent,
                                  EmployerLandingComponent,
                                  RepresentativeLandingComponent,
                                  AppealNthgComponent,
                                  AppealNthgcnfmComponent,
                                  AppealDetComponent,
                                  PageNotFoundComponent,
                                  HomeComponent ];

