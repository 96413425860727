<div class="d-flex flex-wrap">
  <div>
    <h4></h4>
    <span></span>
  </div>
  <div class="sub-hdr-lft pt-4">
    <h4>Other appeals that are joined to this appeal</h4>
    <span></span>

  </div>
</div>


<div class="tab-cnt">
  <div class="main-cnt rnd-01 m-3">
    <div class="tbl-container">

      <div>
        <div class="d-flex flex-wrap p-4">
          <div class="lft-col">
            <h5><span class="hdr-01">PRIMARY APPEAL</span></h5>
            <div class="rgt-spc" *ngFor='let appeal of _joinedAppeals!'>
              <!-- <div *ngIf="!enableAccess(appeal!)">{{appeal?.appealNumber}}</div> -->
              <a class="nav-link initial lnk-grn" aria-current="page" (click)="navAppealDet(appeal.appealNumber, appeal.appealCaseID);"  *ngIf="appeal.isLeadAppeal">{{appeal.appealNumber}} </a>
            </div>
          </div>
          <div class="mid-col">
            <h5><span class="hdr-01">JOINED APPEALS</span></h5>
            <div class="rgt-spc" *ngFor='let appeal of _joinedAppeals!'>
              <a class="nav-link initial lnk-grn" aria-current="page" (click)="navAppealDet(appeal.appealNumber,  appeal.appealCaseID);"  *ngIf="!appeal.isLeadAppeal">{{appeal.appealNumber}} </a>
            </div>
          </div>
          <div class="rgt-col">
            <h5><span class="hdr-01"></span></h5>
            <div class="rgt-spc" class=""></div>
            <h5><span class="hdr-01">&nbsp;</span></h5>
            <div class="rgt-spc">&nbsp;</div>
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>


