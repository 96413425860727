import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightService } from './app-insight.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService extends ErrorHandler {

  constructor(private _appinsight: AppInsightService) {
    super();
  }

  handleError(error: Error) {
    this._appinsight.logException(error);
}
}
