import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalCase'
})
export class capitalCasePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
   let words = value.split(" ");
   for (let i = 0; i < words.length; i++) {
     words[i] = words[i][0].toUpperCase() + words[i].substring(1);
   }
    return words.join(" ");
  }
}
