import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/shared/auth.service';

@Component({
  selector: 'app-home',
  template: `<ng-content></ng-content>`
})
export class HomeComponent implements OnInit {


  constructor(private _authservice: AuthService) {

   }

  ngOnInit(): void {
    this._authservice.initiate();
  }





}
