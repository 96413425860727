export enum appealJoinType
{
    notSet = 0,
    Lead = 100,
    Normal = 200
};


export enum appealParticipationType
{
    notSet = 0,
    appellant = 10,
    responded = 20,
    participant =30,
    representative = 40
};


export enum documentType
{
    notSet = 0,
    "Acknowledgement letter" = 10,
    "Decision letter" = 20,
    "Notice of appeal" = 30,
    "Submissions" = 40
};

