<div class="d-flex flex-wrap">
  <div>
    <h4></h4>
    <span></span>
  </div>
  <div class="sub-hdr-lft pt-4">
    <h4>How the appeal will proceed</h4>
    <span></span>
  </div>
</div>
<div class="tab-cnt">
  <div id="" class="main-cnt rnd-01 m-3">
    <div class="d-flex flex-wrap p-2">


            <!--ORAL-->
            <ng-container *ngIf="_appealDet?.appealPath?.toLowerCase()?.includes('oral')">
            <div class="lft-col" id="">
                <div class="spt-1">
                  <h5><span class="hdr-01">APPEAL METHOD</span></h5>
                      <div class="celCnt">
                        <span class="appl-itm">{{_appealDet?.appealPath}}</span>
                      </div>
                </div>
                <div class="spt-1">
                    <h5><span class="hdr-01">TYPE OF HEARING</span></h5>
                    <div class="celCnt">
                      <span class="appl-itm">{{_typeOfHearing}}</span>
                    </div>
                </div>
                <div class="spt-1">
                  <div class="celCnt">
                    <span class="appl-itm">{{_typeOfHearingStaticText}}</span>
                  </div>
              </div>
            </div>
            <div class="mid-col" id="">
              <div *ngIf="(_appealDet?.hearingDate && _isFuture) || _appealDet?.appealPath?.toLowerCase()?.includes('oral')"><ng-container [ngTemplateOutlet]="HEARINGINFO"></ng-container></div>
            </div>
            <div class="rgt-col" id="">
              <div *ngIf="_remainders"><ng-container [ngTemplateOutlet]="SUBLNKS"></ng-container></div>
            </div>
            </ng-container>


            <!--WRITTEN-->
            <ng-container *ngIf="_appealDet?.appealPath?.toLowerCase()?.includes('written')">
              <div class="lft-col" id="">
                <div class="spt-1">
                  <h5><span class="hdr-01">APPEAL METHOD</span></h5>
                  <div class="celCnt">
                    <span class="appl-itm">{{_appealDet?.appealPath}}</span>
                  </div>
                </div>
                <div class="spt-1">
                  <div>
                    <h5><span class="hdr-01"><!--TYPE OF HEARING --></span></h5>
                    <div class="celCnt">
                      <span class="appl-itm"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mid-col" id="">
                <div><ng-container [ngTemplateOutlet]="WRITTN"></ng-container></div>
              </div>
              <div class="rgt-col" id="">
                <div *ngIf="_remainders"><ng-container [ngTemplateOutlet]="SUBLNKS"></ng-container></div>
              </div>
            </ng-container>

            <!--Post Disclosure & Pre Participation -> BLANK-->

      <ng-template #HEARINGINFO>
         <!--HEARING INFO-->
           <div class="spt-1" >
            <h5><span class="hdr-01" >HEARING DATE</span></h5>
            <div class="celCnt">
              <span class="appl-itm" *ngIf="_isFuture" >{{_appealDet?.hearingDate | date: "mediumDate"}} at {{_appealDet?.hearingTime}}</span>
            </div>
          </div>
          <div class="spt-1">
            <h5><span class="hdr-01" >HEARING LOCATION</span></h5>
            <div class="celCnt">
              <span class="appl-itm">{{_locationText}}</span>
            </div>
          </div>
      </ng-template>

      <ng-template #WRITTN>
        <!--WRITTEN INFO-->
          <div class="spt-1" *ngIf="_appealDet?.submissionLinks!.length > 0">
           <h5><span class="hdr-01">SUBMISSION DUE DATE</span></h5>
           <div class="celCnt">
            <span class="appl-itm" *ngIf="_appealDet?.submissionLinks!.length > 0">{{_appealDet?.submissionLinks![0].deadlineDate | date: "mediumDate"}}</span>
          </div>
         </div>
         <div class="spt-1">
           <h5><span class="hdr-01" ></span></h5>
           <div class="celCnt">
           <span class="appl-itm"></span>
          </div>
         </div>
     </ng-template>

      <ng-template #SUBLNKS>
        <!--SUBMISSION LINKS-->
        <div>
          <div *ngFor="let item of _appealDet?.submissionLinks">
            <div class="spt-1">
              <div class='table-row' ><span class="hdr-01 lnks"><a class="lnk-grn" aria-current="page" matTooltip="{{item.tooltipTxt}}" matTooltipClass="custom-tooltip"  href="javascript:void(0);" (click)="navMakeSub(_appealDet!, item)">{{item.labelText}}</a></span></div>
              <div class='table-row' ><span class="hdr-01 lnks"><a class="lnk-grn" aria-current="page" matTooltip="Nothing to submit." matTooltipClass="custom-tooltip" href="javascript:void(0);" (click)="navMakeNoSub(_appealDet!, item)">Confirm that you have nothing to submit</a></span></div>
            </div>
          </div>
          <div class="spt-1">

          </div>
          <div class="spt-1">
            <!--<span class="hdr-01"><a class="lnk-grn lnks" aria-current="page" href="#">Ask to change the date or time of your oral hearing</a></span>-->
          </div>
        </div>
      </ng-template>


    </div>
  </div>
</div>

















