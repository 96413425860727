
<div class="tab-cnt">

  <div id="" class="main-cnt rnd-01 m-3">
    <div class="tbl-container">

      <table mat-table [dataSource]="_datasource!" matSort (matSortChange)="announceSortChange($event)" matSort>

        <ng-container matColumnDef="AppealNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Appeal Number">APPEAL NUMBER </th>
          <td mat-cell *matCellDef="let element" class="w-25">
            <div class="clm-a">
              <div *ngIf="!enableAccess(element!)">{{element?.appealNumber}}</div>
              <div *ngIf="enableAccess(element!)"><a class="lnk-grn" aria-current="page" href="javascript:void(0);" (click)="navAppealDet(element?.appealNumber, origin!.substring(0,3).toUpperCase(), element?.appealCaseID);">{{element?.appealNumber }}</a></div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Appeal Status"> STATUS </th>
          <td mat-cell *matCellDef="let element" class="w-25"><div class="text-nowrap p-2"> {{ element?.appealStatus == 'Summary Closed' ? 'Closed' : element?.appealStatus }} </div></td> <!--  -->
        </ng-container>

        <ng-container matColumnDef="Type">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Appeal Type"> TYPE </th>
          <td mat-cell *matCellDef="let element" class="w-25"><div class="text-nowrap p-2"> {{element?.appealType }} </div></td>
        </ng-container>

        <ng-container matColumnDef="Employer" *ngIf="origin?.includes('WRKR')">
            <th  mat-header-cell *matHeaderCellDef >  </th>
            <td mat-cell *matCellDef="let element" class="w-25"><div class="text-nowrap"> </div></td>
        </ng-container>

        <ng-container matColumnDef="Employer" *ngIf="origin?.includes('EMPL')">
          <th  mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Employer">  WORKER </th>
          <td mat-cell *matCellDef="let element" class="w-25"><div class="text-nowrap p-2"> {{element?.worker?.workerFirstName}} {{element?.worker?.workerLastName}} </div></td>
      </ng-container> 

        <ng-container matColumnDef="Employer" *ngIf="origin?.includes('REP')">
         <!--  <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Worker | Employer">  CLIENT </th> -->
         <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Client">  CLIENT </th>
          <!-- <td mat-cell *matCellDef="let element" class="w-25"><div class="text-nowrap p-2"> {{ getClient(element) }} </div></td> --> 
          <td mat-cell *matCellDef="let element" class="w-25"><div class="text-nowrap p-2"> {{ element?.clientName }} </div></td>
        </ng-container>

        <ng-container matColumnDef="ActionRequired">
          <th mat-header-cell *matHeaderCellDef ><span *ngIf="origin!.substring(origin!.length -3)!=='PST'"> ACTION REQUIRED</span></th>
          <td mat-cell *matCellDef="let element" class="w-25">
            <div class="minWdthCell">
              <div *ngIf="element != null">
                <div *ngIf="element?.appealProceeding == null; else noaction">
                  <div *ngIf="origin!.substring(origin!.length -3)!=='PST'">
                    <div *ngFor="let item of element?.submissionLinks"  class=''>
                      <span class="cell1"></span><span class="cell2"><a class="lnk-grn" aria-current="page" matTooltip="{{item.tooltipTxt}}" matTooltipClass="custom-tooltip"  href="javascript:void(0);" (click)="navMakeSub(origin!.substring(0,3).toUpperCase(), element!, item)">{{item.labelText}}</a></span><span class="cell3" > </span>
                      <div class='table-row'  *ngIf="element?.submissionLinks?.length > 0"><span class="cell1"></span><span class="cell2"><a class="lnk-grn" aria-current="page" matTooltip="Nothing to submit." matTooltipClass="custom-tooltip" href="javascript:void(0);" (click)="navMakeNoSub(origin!.substring(0,3).toUpperCase(), element!, item)">Confirm that you have nothing to submit</a></span><span class="cell3" ></span></div>
                    </div>

                    <span class="cell1">&nbsp;</span>
                  </div>
          </div>
          </div>
          <ng-template #noaction>
            <div>No action required</div>
          </ng-template>

        </div>
        </td>
        </ng-container>


        <ng-container matColumnDef="DueDate" *ngIf="origin?.includes('ACT')">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Due Date"> ACTION REQUIRED BY </th>
          <td mat-cell *matCellDef="let element" class="w-25">
            <div class="text-nowrap p-2 minWdthCell"> {{element?.appealDueDate | date:'mediumDate'}} </div>
            <div *ngFor="let item of element?.submissionLinks"  class=''>{{item.deadlineDate}}
              <div class='table-row' ><span class="w-25 minWdthCell">&nbsp;</span></div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="DueDate" *ngIf="origin?.includes('PST')">
          <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by Due Date"> APPEAL END DATE </th>
          <td mat-cell *matCellDef="let element"  class="w-25 minWdthCell"><div class="text-nowrap p-2 minWdthCell"> {{element?.appealDecisionDate | date:'mediumDate'}}&nbsp; </div></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>





    </div>
  </div>
  </div>
