import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { AppealHeader, IAppealHeader } from "../../services/models/IAppealHeader";
import { IProfileData } from "../../services/models/IProfileData";
import { Profile001Service } from "../../services/profile/profile001.service";
import { Appeal001Service } from "../../services/appeal/appeal001.service";
import { GlobalSupport } from '../../services/shared/global-support';
import { IIntAppeal } from 'src/app/services/models/IIntAppeal';
import { NotificationService } from 'src/app/services/shared/notification.service';
import { AuthService } from 'src/app/services/shared/auth.service';
import { IIntEmployersForContact } from 'src/app/services/models/IIntEmployersForContact';

@Component({
  selector: 'app-worker-landing',
  templateUrl: './worker-landing.component.html',
  styleUrls: ['./worker-landing.component.css']
})
export class WorkerLandingComponent implements OnInit {
  step = 0;
  _userData: IProfileData | undefined;
  _appealsInProgress : IAppealHeader | undefined;
  _appealsPast : IAppealHeader | undefined;
  _origin: string[] = ['WRKRACT','WRKRPST','WRKR'];
  _employers: IIntEmployersForContact[] | undefined;
  _wcatEmpRoleID: number | undefined;



  constructor(private _profsvc: Profile001Service,
        private _applsvc: Appeal001Service,
        private titleService: Title,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _globalSupport : GlobalSupport,
        private _spinner: NgxSpinnerService,
        private _notifyService : NotificationService,
        private _authSrv: AuthService,
        private _profileSvc: Profile001Service) {
                sessionStorage.removeItem('__wcat.001.sss');
                this._userData = this._authSrv.getUserProfile();
                this._globalSupport.addTracking(this._activatedRoute);

                if(this._authSrv._wCATRoleType !== 'RL1'){
                  this._authSrv.redirectToLandings();
                  return;
                }
              }

  ngOnInit(): void {


    this._spinner.show();
    this._wcatEmpRoleID = 0;
    this._applsvc.getLandingAppeals("0").subscribe(response => {

      this._appealsInProgress = new AppealHeader(this._userData!, "In Progress", response?.activeAppeals!);
      this._appealsPast = new AppealHeader(this._userData!, "Past Appeals", response.pastAppeals!);;

      this._authSrv.updateWorkers();
      
      this._spinner.hide();
    }, error => {
      this._notifyService.showError('Unexpected Error', `We apologize. We are unable to complete your request. Please try again. If you are still experiencing problems, please call 604 664-7800 or 1 800 663-2782 (toll-free in B.C.) or you can email us at appeals@wcat.bc.ca for assistance.`);
    });

    this.titleService.setTitle("WCAT Online Service - Worker Landing");
  }

  setStep(index: number) {
    this.step = index;
  }



}
