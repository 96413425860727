import { ActivatedRoute,Router, UrlSegment } from '@angular/router';
import { Injectable } from '@angular/core'
import { INavData, NavData } from '../models/INavData';
import { SiteData } from '../models/TrackingObjects';
import { Profile001Service } from '../profile/profile001.service';
import { AuthService } from './auth.service';
import { NodeWithI18n } from '@angular/compiler';
import { WcatParameterService } from '../wcat-parameter.service';

@Injectable({
  providedIn: 'root',
})

export class GlobalSupport{

   _tracking: SiteData[];
   _navData: INavData | undefined;


   constructor(private _router: Router,
              private _profileSvc: Profile001Service){
     var session = sessionStorage.getItem('__wcat.nav.sss');
     if(session === undefined || session === null)
      this._tracking = new Array();
     else
      this._tracking = JSON.parse(session);
   }




   setNavDataTrf(navData: INavData){
   this.setNavData(navData.appealNo, navData.origin, navData.lastVisited, navData.origDocumentType, navData.respDocumentType, navData.wcatCasePartRoleID, navData.appealCaseID, navData.claimNumber, navData.submissionType, navData.reference, navData.wcatEmpRoleID);
 }

   setNavData(appealNo?: string, origin?: string, lastvisited?: string, origDocumentType?: string, respDocumentType?: string, wcatCasePartRoleID?: number,appealCaseID?: number, claimNumber?: string, submissionType?: string, reference?: string, wcatEmpRoleID?: number){
      let newNavData = new NavData(0, origin, lastvisited,appealNo,origDocumentType,respDocumentType,wcatCasePartRoleID,appealCaseID,claimNumber, submissionType, reference, wcatEmpRoleID);
      this._navData = newNavData;
      sessionStorage.setItem('__wcat.001.sss',JSON.stringify(newNavData));
   }



   popLastVisitedEntry(): SiteData | undefined{
    if(this._tracking!.length <=1)
      return this._tracking[0];

      this._tracking.pop();
      return this._tracking[this._tracking.length-1];
   }


   popUpToLandingEntry(): SiteData| undefined{
      var landing = this._tracking!.filter(lndn => lndn.type == "L");
      sessionStorage.setItem('__wcat.nav.sss',JSON.stringify(landing));
      this._tracking = landing;
      return landing[0];
   }


   removeNavEntryVisited(routePath :string): void{
    let trackingSss =  sessionStorage.getItem('__wcat.nav.sss');

     if(trackingSss == null || trackingSss === undefined){
       return;
     }
     let tmptracking: SiteData[] = JSON.parse(trackingSss);
     let newwArray = tmptracking.filter(function( item ) { return item.routePath !== routePath;  });
     sessionStorage.setItem('__wcat.nav.sss',JSON.stringify(newwArray));
     this._tracking = newwArray;
   }

   getNavData(): INavData | undefined{
     if(this._navData === undefined){
      let data = sessionStorage.getItem('__wcat.001.sss');
      if(data){
        //data = atob(data!);
        this._navData = JSON.parse(data!);
        return this._navData;
      }else{
        return undefined;
      }
     }else{
     return this._navData;
     }
   }




   addTracking(visited: ActivatedRoute){
    let navData = this.getNavData();
    let appealNo = navData ? navData!.appealNo : null;
    let origin = navData ? navData!.origin : null;

    visited.pathFromRoot[1].url.subscribe((val : UrlSegment[]) => {
      let rootPath = val[0].path;
      let path = val[0].path;
      let data: any = visited.data!;
      //if(appealNo !== null && origin !== null && data.value['type'] !="L"){
      //  path += `/${appealNo}/${origin}`;
      //}

        let visitedData: SiteData = new SiteData(data.value['type'],
                                                path,
                                                data.value['routeLabel'],
                                                data.value['breadCrumbLabel'],
                                                data.value['childSequence'],
                                                data.value['key'],
                                                data.value['parentKey'],
                                                true,
                                                rootPath);

        if(visitedData.type === "I"){
          return;
        }
        if(visitedData.type === "L"){
          this._tracking =  new Array();
        }

        if(visitedData.type === "E"){
          this._tracking.map((itm, idx)=>{
            if (((itm.type != "L") && (itm.type != "C")) || (itm.routeLabel === "Nothing to Submit") ){
              this._tracking[idx].active = false;
              if (itm.routeLabel === "Nothing to Submit")
              {
                if (this._tracking[idx -1].routeLabel === "Appeal Submissions")
                {
                  this._tracking[idx -1].active = false;
                }
              }
            }
          });
        }

        if(!this._tracking.some((trk: SiteData) => trk.rootPath === visitedData.rootPath)){ //trk.routePath === visitedData.routePath)){
           this._tracking.push(visitedData);

        }else{
          let tempTrack = this._tracking.filter((f: SiteData) => f.childSequence <= visitedData.childSequence);
          this._tracking = tempTrack;
        }
        sessionStorage.setItem('__wcat.nav.sss',JSON.stringify(this._tracking));
      });
   }

   goHomeData():SiteData{
    sessionStorage.removeItem('__wcat.001.sss');
    this._navData = undefined;
    return this._tracking.filter(t=>  t.type = "L")[0];
   }
}
